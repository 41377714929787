import { Component, Prop, State, Watch } from 'nuxt-property-decorator'
import { group } from '~/share/utils'
import { ExperienceActivity } from '~/types/experience/activity-section'
import CardAttrBase from '~/components/experience-booking/experience-activity/package-options/base/card-attr-base'

@Component
export default class AttrBase extends CardAttrBase {
  @State klook!: Data.Klook
  @Prop() isInp!: boolean
  @Prop() spec!: ExperienceActivity.Specs
  // 如果选中了日期，则有当前选中日期对应的schedule
  @Prop() value!: number[]
  @Prop() isSingleSpecWithDiscount!: boolean
  @Prop() isSingleSpec!: boolean
  @Prop() activityId!: number
  @Prop({ default: false }) isNewOpenTicket!: boolean
  @Prop() includedPackages!: ExperienceActivity.Packages
  @Prop() includedTagPackages!: ExperienceActivity.Packages
  @Prop({ default: () => [] }) attractionIncluded!: number[]
  @Prop({ default: false }) isEnableReverseSelect!: boolean
  chosenAttrIds: number[] = []
  isPackageNotAvailable = false
  isScheduleNotAvailable = false
  selectSpecIdSet = new Set()

  inpHandler(callback: Function, timeout = 0) {
    if (this.isInp) {
      setTimeout(callback, timeout)
    } else {
      callback()
    }
  }

  @Watch('value', { immediate: true })
  valueChange(val: number[]) {
    if (val && val.length > 0) {
      this.chosenAttrIds = this.addSingleAttrIds(val)
    } else {
      this.chosenAttrIds = this.klook.platform === 'desktop' ? [] : this.addSingleAttrIds()

      // fix: mweb 父组件packageId没有被初始化
      if (this.chosenAttrIds.length > 0 && this.packageId) {
        this.$emit('selectPackage', this.packageId)
      }
    }
  }

  @Watch('specList', { immediate: true, deep: true })
  specListChange(val: ExperienceActivity.Specs, oldVal: ExperienceActivity.Specs) {
    if (!val) {
      return
    }
    let isPackageNotAvailable = false
    let isScheduleNotAvailable = false
    for (const specItem of val) {
      const { attr } = specItem
      if (!isPackageNotAvailable) {
        isPackageNotAvailable = attr.some(v => !v.isPackageMatch && v.isScheduleMatch)
      }
      if (!isScheduleNotAvailable) {
        isScheduleNotAvailable = attr.some(v => !v.isScheduleMatch)
      }
    }
    this.isPackageNotAvailable = isPackageNotAvailable
    this.isScheduleNotAvailable = isScheduleNotAvailable

    if (val && oldVal) {
      this.$emit('specFilterNone', val.length === 0)
    }
  }

  @Watch('includedAttrIdSet')
  includedAttrIdSetChange(val: Set<number>) {
    // 如果选中了销售属性，但是这个销售属性不被包含，则取消选中这个销售属性
    const shouldDeleteAttrs = this.chosenAttrIds.filter(id => !val.has(id))
    this.$emit('deleteAttrs', [...shouldDeleteAttrs], this.specList)
  }

  autoSelectFirstAttr() {
    // 如果没有销售属性选中，则选中第一个可选的
    // 注意：这里只考虑单销售属性的情况（多属性情况会有bug）
    // 注意：这里要加 nexttick，确保 includedTagAttrIdSet 已经变化
    this.$nextTick(() => {
      const chosenAttrIdsCopy = [...this.chosenAttrIds]
      const filteredAttrIds = this.chosenAttrIds.filter(id => this.includedTagAttrIdSet.has(id))

      if (!filteredAttrIds?.length) {
        // 重置为空(这里小心使用，因为chosenAttrIds数据和package option不一致了)
        this.chosenAttrIds = []

        const spec = this.specList?.[0]
        let index = -1
        for (const item of (spec?.attr || [])) {
          index += 1
          if (item.isPackageMatch && item.isScheduleMatch && item.isIncluded && item.isTagIncluded) {
            this.handleSelect(item, spec, { type: '1stSystem', index, len: spec?.attr?.length ?? -1 })
            return
          }
        }

        // 如果没有找到符合要求的，则清空
        this.$emit('deleteAttrs', chosenAttrIdsCopy, this.specList)
      } else {
        // hero page定制，滑动到中间
        const { heroSlideToCenter } = this as any
        if (typeof heroSlideToCenter === 'function') {
          heroSlideToCenter()
        }
      }
    })
  }

  get packageId() {
    const isAttrsSelectedAll = this?.spec?.length === this.chosenAttrIds.length

    if (!isAttrsSelectedAll) {
      return null
    }

    // 选择了全部销售属性，此时可以获得packageId了
    const selectedPackage = this.packages.find((v) => {
      return this.getAttrKey(v.spec_attr_id) === this.getAttrKey(this.chosenAttrIds)
    })

    return selectedPackage && selectedPackage.package_id
  }

  get specList() {
    return this.spec?.reduce((acc, v) => {
      const { attr } = v

      const attrs = attr.map((attr) => {
        // 折扣信息：单个spec的话，并且有促销的话，需要显示折扣信息
        let promoTag
        const singleAttrPackage: any = this.packages.find(pkg => pkg.spec_attr_id?.includes(attr.id)) || {}
        if (this.isSingleSpecWithDiscount) {
          const { product_tags } = singleAttrPackage || {}
          const { discount_tags = [] } = product_tags || {}
          promoTag = discount_tags ? discount_tags[0] : ''
        }

        return {
          ...attr,
          openDateTip: singleAttrPackage?.usage_validity_tips,
          isPackageMatch: this.getIsPackageMatch(attr, this.removeAttrIdInSameSpec(v)),
          isScheduleMatch: this.getIsScheduleMatch(attr),
          isActive: this.chosenAttrIds.includes(attr.id),
          isIncluded: this.includedAttrIdSet.has(attr.id),
          isTagIncluded: this.includedTagAttrIdSet.has(attr.id),
          singleAttrPackageId: singleAttrPackage?.package_id || null,
          promoTag
        }
      })

      // 筛掉 attraction included 和 tag included 筛掉的套餐
      const filteredAttrs = attrs.filter(attr => attr.isIncluded && attr.isTagIncluded)
      return filteredAttrs.length === 0 ? acc : acc.concat({
        ...v,
        attr: filteredAttrs
      })
    }, [] as ExperienceActivity.Specs)
  }

  getAttrKey(ids: number[]) {
    return [...ids].sort().join(',')
  }

  addIdToAttrKey(key: number, chosenAttrIds = this.chosenAttrIds) {
    return this.getAttrKey(Array.from(new Set(chosenAttrIds).add(key)))
  }

  getIsScheduleMatch(attr: ExperienceActivity.IAttr) {
    if (!this.schedule || this.isNewOpenTicket) {
      return true
    }

    return !!(this.packagesInSchedule.find(pkg => pkg.spec_attr_id?.includes(attr.id)))
  }

  getIsPackageMatch(attr: ExperienceActivity.IAttr, chosenAttrIds = this.chosenAttrIds) {
    const { id } = attr

    const attrKey = this.addIdToAttrKey(id, chosenAttrIds)

    /**
     * 如某个日期下
     * A B C 构成套餐，但无库存
     * D B E 构成套餐，有库存
     * A D属于同一销售性，C E属于同一销售属性，B属于单独的销售属性
     * 选择AC后，B虽然可以组合成套餐，但不应该可选，同时因为不展示日期不可用的样式，所以也是算为不构成套餐的
     */
    const isPackageSkuInSchedule = this.packagesInSchedule.some((pkg) => {
      return [...chosenAttrIds, id].every(id => pkg.spec_attr_id?.includes(id))
    })

    return !!(this.includedAttrIdGroups.has(attrKey) && (this.isNewOpenTicket || isPackageSkuInSchedule))
  }

  // 去除同级的已选销售属性
  removeAttrIdInSameSpec(spec: ExperienceActivity.ISpec) {
    return this.chosenAttrIds.filter(id => !spec.attr.find(attr => attr.id === id))
  }

  formatAttrIdGroups(packages: any[]) {
    return (packages || []).reduce((acc: any, v: ExperienceActivity.IPackage) => {
      const specAttrId = v.spec_attr_id || []
      // 如果销售属性id的组合对应一个套餐的完整attrId，将套餐暴露出去便于获取选中套餐
      return group(specAttrId).reduce((idAcc, ids) => idAcc.add(this.getAttrKey(ids)), acc)
    }, new Set())
  }

  // 经过 included activities 筛选的销售属性
  get includedAttrIdSet() {
    return (this.includedPackages || []).reduce((acc: any, pkg: ExperienceActivity.IPackage) => {
      for (const attrId of (pkg?.spec_attr_id || [])) {
        acc.add(attrId)
      }
      return acc
    }, new Set())
  }

  // 经过 tag 筛选的销售属性
  get includedTagAttrIdSet() {
    // 如果没传就是全选
    return (this.includedTagPackages || this.packages || []).reduce((acc: any, pkg: ExperienceActivity.IPackage) => {
      for (const attrId of (pkg?.spec_attr_id || [])) {
        acc.add(attrId)
      }
      return acc
    }, new Set())
  }

  // 经过 included activities 筛选的销售属性组合
  get includedAttrIdGroups() {
    return this.formatAttrIdGroups(this.includedPackages || [])
  }

  get singleAttrIds() {
    return this.spec?.reduce((acc, spec) => {
      const { attr } = spec
      if (attr && attr.length === 1) {
        return [...acc, attr[0].id]
      }

      return acc
    }, [] as number[])
  }

  get singleSpecIds() {
    return this.spec?.reduce((acc, spec) => {
      const { attr } = spec

      if (attr && attr.length === 1) {
        return [...acc, spec.id]
      }

      return acc
    }, [] as string[])
  }

  addSelectSpecIdSet(id: string) {
    this.selectSpecIdSet.delete(id)
    this.selectSpecIdSet.add(id)
    this.inpHandler(() => {
      this.$emit('selectSpec', Array.from(this.selectSpecIdSet))
    })
  }

  // 只有单个销售属性默认选中
  addSingleAttrIds(ids?: number[]) {
    // 如果能支持反选的话，就不默认选中
    if (this.isEnableReverseSelect) {
      return ids || []
    }

    const allIds = (ids || []).concat(this.singleAttrIds)
    return Array.from(new Set(allIds))
  }

  isAttrDisabled(attr: ExperienceActivity.IAttr) {
    return !attr.isScheduleMatch || !attr.isPackageMatch
  }

  change(
    attr: ExperienceActivity.IAttr,
    spec: ExperienceActivity.ISpec,
    spmObj: Record<string, string | number> | null = null
  ) {
    // 套餐属性选中状态改变
    const { id, isScheduleMatch, isPackageMatch } = attr

    // 和当前日历不匹配，清空日历
    if (!isScheduleMatch) {
      this.inpHandler(() => {
        this.$emit('date-change', '')
      })
    }

    // 和已选销售属性构成的套餐不匹配，清空已选销售属性
    if (!isScheduleMatch || !isPackageMatch) {
      this.chosenAttrIds = this.addSingleAttrIds([id])
      this.selectSpecIdSet = new Set(this.singleSpecIds)
    } else {
      // 同一层级只判断是否和其他层级构成套餐，但是同一层级依然是互斥的，只能选一个
      const otherChooseIds = this.removeAttrIdInSameSpec(spec)
      this.chosenAttrIds = this.addSingleAttrIds([...otherChooseIds, id])
    }

    this.addSelectSpecIdSet(spec.id)
    this.inpHandler(() => {
      this.$emit('input', this.chosenAttrIds, this.packageId, attr, spmObj)
      this.$emit('change')
      this.$emit('swiper')
    })
  }

  reverseSelect(attrId: number) {
    if (this.isEnableReverseSelect && this.chosenAttrIds.includes(attrId)) {
      this.inpHandler(() => {
        this.$emit('deleteAttrs', [attrId], this.specList)
      })
    }
  }

  handleSelect(
    attr: ExperienceActivity.IAttr,
    spec: ExperienceActivity.ISpec,
    spmObj: Record<string, string | number> | null = null
  ) {
    if (attr.isActive) {
      this.reverseSelect(attr.id)
      return
    }

    if (!attr.isScheduleMatch) {
      // web端所选日期不可用直接return
      if (this.klook.platform === 'desktop') {
        return
      }

      // mweb端所选日期不可用交互
      this.$confirm(this.$t('activity.new.package_attr_date_disabled_tip')).then(({ result }) => {
        if (result) {
          this.change(attr, spec, spmObj)
        }
      })
      return
    }

    if (!attr.isPackageMatch) {
      this.$toast(this.$t('activity.new.package_attrs_updated'))
    }

    this.change(attr, spec, spmObj)
  }
}
