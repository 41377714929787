import { Vue, Component } from 'nuxt-property-decorator'

@Component({})
export default class WebVitalsMixins extends Vue {
  private webVitalsMixinsData: any = {
    timer: null,
    lazyVisible: false
  }

  public get calcLazyVisible() {
    return this.webVitalsMixinsData.lazyVisible
  }

  public setLazyVisible(isb: boolean, options?: any) {
    const opt: any = {
      delayTime: 0,
      callback: (isb: boolean) => isb
    }
    if (typeof options === 'number') {
      opt.delayTime = options > 0 ? options : 0
    } else if (typeof options === 'function') {
      opt.callback = options
    } else if (typeof options === 'object') {
      Object.assign(opt, options)
    }
    const { delayTime, callback } = opt
    const { webVitalsMixinsData: obj } = this
    if (isb) {
      clearTimeout(obj.timer)
      obj.timer = setTimeout(() => {
        obj.lazyVisible = true
        callback && callback(obj.lazyVisible)
      }, delayTime)
    } else {
      obj.lazyVisible = false
      callback && callback(obj.lazyVisible)
    }
  }

  beforeDestroy() {
    clearTimeout(this.webVitalsMixinsData.timer)
  }
}
