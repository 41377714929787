



































































import dayjs from 'dayjs'
import { Component, Vue, Prop, Watch } from 'nuxt-property-decorator'
import HeaderTips from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/components/header-tips.vue'
import DesktopCalendar from '~/components/experience-booking/experience-activity/package-options/desktop/unfold-card/desktop-calendar.vue'
import MiniTime from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/components/mini-time.vue'
import { ExperienceActivity } from '~/types/experience/activity-section'
import eventBus from '~/pages/experience/pay/common/event-bus'
import ShowMoreBox from '~/components/experience-booking/experience-activity/show-more-box/index.vue'

export interface Schedule {
  selling_price: number,
  soldout: boolean,
  date: string,
  stocks: {
    from_price: string,
    market_price: string,
    package_id: number,
    selling_price: string,
    stock: number,
    to_price: string
  }[]
  disable?: boolean,
  time_slots?: any[]
}

@Component({
  components: {
    ShowMoreBox,
    HeaderTips,
    DesktopCalendar,
    MiniTime
  }
})
export default class BookingCalendar extends Vue {
  @Prop() packageId!: number
  @Prop() currentSelectedPackage!: ExperienceActivity.IPackageCard
  @Prop() isNewOpenTicket!: boolean
  @Prop({ default: () => [] }) schedules!: Schedule[]
  @Prop() selectedTime!: any
  @Prop() value!: string // date
  @Prop() warn!: string
  @Prop({ default: () => [] }) legends!: any[]
  @Prop({ default: '' }) inhouseModule!: string
  @Prop() hideIcon!: boolean

  dateLimit = 9999
  timeLimit = 9
  calendarPopupVisible = true
  timePopupVisible = false

  formatTimeStr = ''

  @Watch('timeList', { immediate: true })
  timeListChange() {
    // 自动选择逻辑
    const { timeList, isNewOpenTicket } = this
    if (timeList && timeList.length > 0) {
      this.$nextTick(() => {
        // 如果只有一个，则自动选中
        if (timeList.length === 1) {
          this.handleSelectTime(timeList[0])
          return
        }

        // 如果是 openTicket，则自动选第一个合法的
        if (isNewOpenTicket) {
          const validTime = timeList.find((item: any) => !item.disabled)
          this.handleSelectTime(validTime)
          return
        }

        // 如果以上都不是，则清除
        this.handleSelectTime(null)
      })
    }
  }

  get calcShowMoreStatus() {
    const { calcHideTimeIcon } = this
    return calcHideTimeIcon ? '' : 'more'
  }

  get calcHideTimeIcon() {
    const { timeList, timeLimit } = this
    return timeList.length <= timeLimit
  }

  clickTimeHandler() {
    if (this.calcHideTimeIcon) { return false }
    this.timePopupVisible = true
  }

  created() {
    eventBus.$off('checkSelectDateAndTime2bus').$on('checkSelectDateAndTime2bus', this.checkSelectDateAndTime2bus)
  }

  beforeDestroy() {
    eventBus.$off('checkSelectDateAndTime2bus', this.checkSelectDateAndTime2bus)
  }

  checkSelectDateAndTime2bus(payload: any) {
    const { value, showTime, selectedTime } = this
    if (!value) {
      this.$toast(this.$t('activity.booking.options.select.date'))
    } else if (showTime && !selectedTime?.time_slot) {
      this.$toast(this.$t('activity.booking.options.select.time'))
    } else if (payload?.message) {
      this.$toast(this.$t(payload.message))
    }
  }

  get validDates() {
    const days = this.limitDays(this.dateLimit, this.schedules)
    const schedules = this.schedules.slice(0, this.dateLimit)
    const d = days.map((item: string) => {
      const val = schedules.find((day: Schedule) => {
        return item === day.date
      })

      return val || {
        date: item,
        selling_price: '',
        soldout: false,
        stocks: [],
        disable: true
      }
    })
    return d
  }

  get timeList() {
    const schedule = this.schedules.find(item => item.date === this.value)
    const timeslots = (schedule && schedule.time_slots) || []

    return timeslots.reduce((acc, v: any) => {
      const disabled = this.isTimeDisabled(v)
      if (disabled) {
        return acc
      }
      return [...acc, {
        ...v,
        disabled: this.isTimeDisabled(v),
        date: `${this.value} ${v.time_slot}`
      }]
    }, [])
  }

  get validTimeList() {
    return this.timeList.slice(0, this.timeLimit)
  }

  get showTime() {
    // 如果为 openTicket 则不显示
    if (this.isNewOpenTicket) {
      return false
    }

    // 如果只有一个并且为 00：00：00 则不显示
    if (this.timeList?.length === 1) {
      const [time] = this.timeList
      const formatTime = dayjs(time.date, 'YYYY-MM-DD hh:mm:ss')
      return !(formatTime.hour() === 0 && formatTime.minute() === 0 && formatTime.second() === 0)
    }

    return this.timeList.length > 0
  }

  isTimeDisabled(time: { stock: number }) {
    const { stock } = time
    return !stock || stock < Number(this.currentSelectedPackage?.min_pax || 0)
  }

  // 从第一个可预订日期开始，延后14天
  // 不能晚于schedules的最后一天，所以可能少于14个
  limitDays(limit: number, schedules: Schedule[]) {
    const validDayDate = schedules.find((day: Schedule) => !day.soldout)?.date
    const lastDayDate = schedules[this.schedules.length - 1]?.date
    const arr = []

    if (validDayDate) {
      for (let i = 0; i < limit; i++) {
        const date = dayjs(validDayDate).add(i, 'day')
        if (!date.isAfter(lastDayDate, 'day')) {
          arr.push(date.format('YYYY-MM-DD'))
        }
      }
    } else {
      // 如果没有可预订日期，则默认从今天开始
      for (let i = 0; i < limit; i++) {
        arr.push(dayjs().add(i, 'day').format('YYYY-MM-DD'))
      }
    }

    return arr
  }

  handleDateChange(date: string) {
    this.$emit('input', date)
    // this.handleScrollToAnchor()
  }

  handleSelectTime(data: any) {
    const timeRef: any = this.$refs.timeRef
    timeRef?.formatTime && (this.formatTimeStr = timeRef.formatTime(data))
    // 重置selectedTime
    if (!data) {
      this.$emit('setTime', null)
      return
    }

    const selectedTime = {
      ...data,
      arrangementId: data.arrangement_id || data.arrangementId,
      stock: data.stock,
      date: data.date,
      /**
       * 最多可购买数量
       * 取决于当前库存和套餐设置的最大可购买数量的最大值
       * 因为在unit的计算中要使用到，所以放到这里先处理出来
       */
      maxPurchased: Math.min(data.stock, Number(this.currentSelectedPackage?.max_pax))
    }
    this.$emit('setTime', selectedTime)
  }

  handleScrollToAnchor() {
    if (process.client) {
      setTimeout(() => {
        const node = this.$el.querySelector('.js-scroll-anchor')
        if (node) {
          node.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
          })
        }
      }, 160)
    }
  }

  clickShowMore() {
    this.timeLimit = 9999
  }
}
