import { Vue, Component, Prop, State } from 'nuxt-property-decorator'
import dayjs from 'dayjs'
import { Schedule } from '~/components/common/calendar/types'
import { getAvilableDateRange, getLocalDate } from '~/components/experience-booking/experience-activity/package-options/activity-calendar/utils/date-picker'

@Component({})
export default class FlotingLayoutMixins extends Vue {
  @State klook!: Data.Klook
  @Prop({ default: '' }) packageName!: string
  @Prop({ default: 0 }) calendarDays!: number
  @Prop({ default: () => [] }) legends!: any[]
  @Prop() actSchedules!: Schedule[]
  @Prop() pkgSchedules!: Schedule[]
  @Prop() packageId!: number
  @Prop({ default: false }) isPackageSchedule!: boolean
  @Prop() value!: string
  @Prop() warn!: string
  @Prop({ default: true }) tipsVisible?: boolean // 可选
  @Prop({ default: true }) priceVisible?: boolean // 可选
  @Prop({ default: false }) isPassReserve?: boolean // 可选：pass预约页面不需要

  datePicker: any = {}

  headerData = {
    height: 0
  }

  get calcSchedules() {
    return this.isPackageSchedule ? this.pkgSchedules : this.actSchedules
  }

  get calcDatePickerProps() {
    const obj = {
      date: this.calcDate,
      'should-disable-date': this.shouldDateDisabled,
      'min-date': this.calcMinDate,
      'max-date': this.calcMaxDate,
      'default-view-date': this.defaultViewDate
    }
    return obj
  }

  shouldDateDisabled(d: string) {
    const date = this.getFormatDate(d)
    return !this.getSchedule(d) || this.isSoldout(date)
  }

  initScrollHandler() {
    const ref: any = this.$refs.expDatePickerRef
    typeof ref?.initScrollHandler === 'function' && ref.initScrollHandler()
  }

  headerUpdateHandler(data: any) {
    this.headerData.height = data?.height || 0
  }

  get calcDatePickerStyles() {
    const { headerData } = this
    const obj = {
      maxHeight: `calc(100% - ${headerData.height}px)`
    }
    return obj
  }

  get schedulesMap(): { [prop: string]: Schedule } {
    return this.calcSchedules?.reduce((acc, v) => ({ ...acc, [v.date]: v }), {})
  }

  getSchedule(d: string) {
    const date = this.getFormatDate(d)
    return this.schedulesMap[date]
  }

  getPrice(d: string) {
    return this.getSchedule(d)?.selling_price
  }

  showItemLegend(item: any) {
    return !!item?.legend_keys?.length
  }

  formatDay(d: string) {
    return dayjs(d).format('D')
  }

  getFormatDate(d: string) {
    return dayjs(d).format('YYYY-MM-DD')
  }

  isSoldout(d: string) {
    const date = this.getFormatDate(d)
    const schedule: any = this.schedulesMap[date]

    // pass 预约做了优化，如果timeSlots长度为1，stock为0，表示售磬
    if (this.isPassReserve && schedule) {
      const timeSlots = schedule.time_slots
      const result = timeSlots && timeSlots.length === 1 && timeSlots[0].stock === 0
      return schedule.soldout || result
    }

    // 这里如果选不到日期是disabled，不当做售罄处理
    if (schedule) {
      return schedule.soldout
    }
  }

  get hasLegend() {
    return this.calcSchedules.some((item: any) => item.legend_keys?.length > 0)
  }

  get calcDate() {
    return this.value ? this.getLocalDate(this.value) : null
  }

  get avilableDateRange() {
    if (this.calcSchedules.length === 0 && this.isPassReserve) {
      return {
        minDate: getLocalDate(dayjs()),
        maxDate: getLocalDate(dayjs().endOf('year'))
      }
    }

    return getAvilableDateRange({
      isPackageSchedule: this.isPackageSchedule,
      packageId: this.packageId,
      schedules: this.calcSchedules,
      calendarDays: this.calendarDays
    })
  }

  get calcMinDate() {
    const { minDate } = this.avilableDateRange
    return minDate
  }

  get calcMaxDate() {
    const { maxDate } = this.avilableDateRange
    return maxDate
  }

  get defaultViewDate() {
    const item: any = this.calcSchedules?.find((obj: any) => {
      return obj?.time_slots?.some((o2: any) => {
        return o2?.stock
      })
    })
    return item?.date ? this.getLocalDate(item?.date) : this.calcMinDate
  }

  getLocalDate(val?: string) {
    const localeDate = dayjs(val || undefined).toString()
    return new Date(localeDate)
  }

  get showBestPriceInfo() {
    return !this.isPackageSchedule || !this.packageId
  }

  isEmpty(v: any[]) {
    return !v || !v.length
  }

  get hasSchedule() {
    const isPackageScheduleNone = this.isPackageSchedule && this.isEmpty(this.pkgSchedules)
    const isScheduleNone = !this.isPackageSchedule && this.isEmpty(this.actSchedules)
    return !(isPackageScheduleNone || isScheduleNone)
  }
}
