var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BookingBarWrap',{ref:"fixedWrapRef",staticClass:"js-hack-total-footer booking-bottom-bar",attrs:{"price-visible":!_vm.isMultiPass,"is-sold-out":_vm.isSoldOut}},[(_vm.bookingSettlementVisible)?_c('ExpBookingSettlement',_vm._b({ref:"bookingSettlementRef",staticClass:"unfold-booking-settlement",attrs:{"slot":"settlement"},on:{"clickSavePrice":_vm.clickSavePrice},slot:"settlement"},'ExpBookingSettlement',_vm.bookingSettlementBinds,false)):_vm._e(),_vm._v(" "),_c('div',{staticClass:"activity-booking-options_bottom-bar-buttons",attrs:{"slot":"buttons"},slot:"buttons"},[(_vm.isBtnVisibleAddtocart)?_c('BookingBottomBtns',_vm._b({key:"addToCart",staticClass:"add-to-card js-add-to-cart",attrs:{"type":"addToCart","btn-content":_vm.addToCartText},on:{"next-btn-complete":_vm.updateModuleExtra,"closeModal":_vm.handleCloseModalEvent}},'BookingBottomBtns',Object.assign({}, _vm.bookingBottomBtnsBinds, _vm.bindIHTrack({
        exposure: false,
        type: 'module',
        spm: 'PackageOptionsAddToCartBtn',
        query: {
          oid: ("package_" + _vm.packageId),
          ext: JSON.stringify({
            CompletedInfo: _vm.readyPrePrice
          })
        }
      })),false)):_vm._e(),_vm._v(" "),(_vm.isBtnVisibleBookNow)?_c('BookingBottomBtns',_vm._b({key:"bookNow",attrs:{"btn-content":_vm.isMultiPass ? this.$t('31062-reserve_slot') : '',"type":"bookNow"}},'BookingBottomBtns',Object.assign({}, _vm.bookingBottomBtnsBinds, _vm.bindIHTrack({
        exposure: false,
        type: 'module',
        spm: 'PackageOptionsBookNowBtn',
        query: {
          oid: ("package_" + _vm.packageId),
          ext: JSON.stringify({
            CompletedInfo: _vm.readyPrePrice
          })
        }
      })),false)):_vm._e(),_vm._v(" "),(_vm.isBtnVisibleSubscribe)?_c('SubscribeButton',_vm._b({key:"subscribe",attrs:{"type":"subscribe"}},'SubscribeButton',Object.assign({}, _vm.bookingBottomBtnsBinds, _vm.bindIHTrack({
        exposure: false,
        type: 'module',
        spm: 'PackageOptionsNotifyMe',
        query: {
          oid: ("package_" + _vm.packageId),
          ext: JSON.stringify({
            activity_id: _vm.activityId,
            selected_date: _vm.packageSelectedDate
          })
        }
      })),false)):_vm._e(),_vm._v(" "),_c('ShoppingCart',{attrs:{"position":_vm.source,"visible":_vm.shoppingCartVisible},on:{"update:visible":function($event){_vm.shoppingCartVisible=$event}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }