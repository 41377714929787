























































import { Component, Prop, Vue, Watch } from 'nuxt-property-decorator'
import MerchatImageGallery from './merchant-image-gallery.vue'
import CustomerImageGallery from './customer-image-gallery'
import ActivityModal from '~/components/experience-booking/experience-activity/activity-modal/index.vue'

@Component({
  components: {
    MerchatImageGallery,
    CustomerImageGallery,
    ActivityModal
  }
})
export default class BannerGallery extends Vue {
  @Prop() visible !: boolean
  @Prop() aid !: number
  @Prop() type !: string
  @Prop() merchatImageAmount !: number
  @Prop() customerImageAmount !: number
  @Prop() activityBanner !: any[]

  active: string = this.type

  closeGallery() {
    this.$emit('close')
  }

  delayHandler(isb: boolean) {
    if (isb) {
      this.$nextTick(() => {
        this.$inhouse.track('pageview', '.activity-gallery', { force: true })
      })
    }
  }

  @Watch('type', { immediate: true })
  onTypeChanged(val: string) {
    this.active = val
  }

  @Watch('active', { immediate: true })
  onActiveChanged(val: string) {
    this.$emit('updateType', val)
  }

  get formatCustomerImageAmount() {
    return this.customerImageAmount > 9999 ? '9999+' : this.customerImageAmount
  }

  get hasNoCustomerImage() {
    return this.customerImageAmount === 0
  }
}
