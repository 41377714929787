import { Vue, Component } from 'nuxt-property-decorator'
import { isEmpty, merge, cloneDeep } from 'lodash'
import dataUtils from '~/components/booking-core/utils/data-utils'
import apis from '~/share/data/apis'
import * as dataTypesUtils from '~/components/booking-core/utils/types'
import { urlTemplate } from '~/share/utils'
@Component({
  components: {
    //
  }
})
export default class Index extends Vue {
  // activitySchedulesApiDefined 数据定义、数据处理、请求参数、请求方法 分组注释 start
  activitySchedulesApiDefined: dataTypesUtils.ApiDefinedIf = {
    state: '',
    url: apis.activityPriceSchedules,
    params: {
      activity_id: 0,
      preview: 0
    },
    apiData: null
  }

  get calcActScheduleList() {
    // 获取活动日历价格库存
    const { apiData } = this.activitySchedulesApiDefined
    const schedules = apiData?.result?.schedules
    if (!schedules?.length) {
      return []
    }
    const arr = schedules.map((v: any) => {
      const { stocks } = v
      return {
        ...v,
        soldout: !stocks.reduce?.((acc: any, stock: any) => acc + stock.stock, 0)
      }
    })
    return arr
  }

  async getActivitySchedulesApiData(paramsData: any, mergeDefined?: any) {
    // 获取活动层级的日历价格库存接口，排队状态不需要调接口
    const params = cloneDeep(paramsData)
    if (!params?.activity_id || params?.isLinePage) {
      return
    }
    const { activitySchedulesApiDefined } = this
    activitySchedulesApiDefined.params = { ...params, isLinePage: undefined }
    merge(activitySchedulesApiDefined, mergeDefined || {})
    const apiData = await dataUtils.getApiDataHandler(this, activitySchedulesApiDefined)
    return apiData
  }
  // 数据定义、数据处理、请求参数、请求方法 分组注释  end

  // packageSchedulesAndUnitsApiDefined 数据定义、数据处理、请求参数、请求方法 分组注释 start
  packageSchedulesAndUnitsApiDefined: dataTypesUtils.ApiDefinedIf = {
    isCache: true,
    getCacheKey(apiDefined: dataTypesUtils.ApiDefinedIf) {
      return apiDefined?.params?.package_id
    },
    state: '',
    reloadConfig: {
      isReady: true,
      delay: 240,
      state: ''
    },
    url: apis.getPackagePriceSchedulesAndUnits,
    params: {
      translation: false,
      package_id: 0,
      preview: 0
    },
    apiData: null
  }

  get calcPkgLegendList() {
    // 套餐日历自定义图例说明
    const { apiData } = this.packageSchedulesAndUnitsApiDefined
    return apiData?.result?.legend || []
  }

  get calcPkgSchedulesUnitsObj() {
    // 套餐日历价格库存和units
    const { apiData } = this.packageSchedulesAndUnitsApiDefined
    const { success, result } = apiData || {}
    if (!success || !result) {
      return {}
    }
    const { schedules: _schedules = [], pre_sale_start_date = '', pre_sale_end_date = '' } = result
    const has_presale = Boolean(pre_sale_start_date && pre_sale_end_date)
    const schedules = has_presale ? dataUtils.getExtendedSchedulesWithPresaleDates(_schedules, pre_sale_start_date, pre_sale_end_date) : _schedules
    const obj = {
      ...result,
      schedules: schedules?.map((item: any) => {
        const { time_slots, pre_sale = false } = item
        const stock = time_slots.reduce((acc: number, val: any) => {
          return acc + val.stock
        }, 0)
        return {
          ...item,
          stock,
          soldout: !(stock) && !pre_sale,
          pre_sale
        }
      }),
      has_presale
    }
    return obj
  }

  get calcPkgScheduleList() {
    // 套餐库存列表
    return this.calcPkgSchedulesUnitsObj?.schedules || []
  }

  get calcPkgCalendarDays() {
    return this.calcPkgSchedulesUnitsObj?.calendar_days || 0
  }

  async getPackageSchedulesAndUnitsApiData(paramsData: any, mergeDefined?: any) {
    // 获取套餐层级的日历价格库存接口
    const params = cloneDeep(paramsData)
    if (!params.package_id) {
      return
    }
    const { packageSchedulesAndUnitsApiDefined } = this
    packageSchedulesAndUnitsApiDefined.params = { ...params, pkgSelectedTime: undefined }
    merge(packageSchedulesAndUnitsApiDefined, mergeDefined || {})
    const apiData = await dataUtils.getApiDataHandler(this, packageSchedulesAndUnitsApiDefined)
    const { success, result } = apiData || {}
    if (success && !isEmpty(result?.units)) {
      this.$set(result, 'unitList', dataUtils.getExpExtraUnitList(params.pkgSelectedTime, { prices: result.units }))
    }
    return apiData
  }
  // packageSchedulesAndUnitsApiDefined 数据定义、数据处理、请求参数、请求方法 分组注释 end

  // packageUnitsApiDefined 数据定义、数据处理、请求参数、请求方法 分组注释 start
  packageUnitsApiDefined: dataTypesUtils.ApiDefinedIf = {
    cancelTokenConfig: {
      cancelToken: null,
      regularUrl: '/v1/usrcsrv/arrangements/{*}/units'
    },
    isCache: false,
    state: '',
    url: apis.getPackageUnits,
    params: {
      translation: false,
      package_id: 0,
      preview: 0
    },
    apiData: null
  }

  async getPackageUnitsApiData(paramsData: any, mergeDefined?: any) {
    // 获取unit层级的价格库存接口
    const params = cloneDeep(paramsData)
    if (!params?.arrangement_id) {
      return
    }
    const { packageUnitsApiDefined } = this
    packageUnitsApiDefined.params = { ...params, pkgSelectedTime: undefined, arrangement_id: undefined }
    packageUnitsApiDefined.url = urlTemplate(apis.getPackageUnits, { arrangementId: params.arrangement_id })
    merge(packageUnitsApiDefined, mergeDefined || {})
    const apiData = await dataUtils.getApiDataHandler(this, packageUnitsApiDefined)
    const { success, result } = apiData || {}
    if (success && !isEmpty(result) && !isEmpty(result.prices) && !isEmpty(result.inventories)) {
      this.$set(result, 'unitList', dataUtils.getExpExtraUnitList(params.pkgSelectedTime, result))
    }
    return apiData
  }
  // packageUnitsApiDefined 数据定义、数据处理、请求参数、请求方法 分组注释 end

  // activityPackageSkuInfoList 数据定义、数据处理、请求参数、请求方法 分组注释 start
  // 切换时间时请求
  activitySkuInfoApiDefined: dataTypesUtils.ApiDefinedIf = {
    state: '',
    url: apis.activityPackageSkuInfoList,
    params: {
      date: '',
      preview: 0,
      items: []
    },
    apiData: null
  }

  async getActivitySkuInfoApiData(paramsData: any) {
    // 获取活动 sku 信息接口
    if (!paramsData.items?.length) {
      return
    }
    const { activitySkuInfoApiDefined } = this
    activitySkuInfoApiDefined.params = paramsData
    const apiData = await dataUtils.getApiDataHandler(this, activitySkuInfoApiDefined)
    return apiData
  }

  getIsLoadingForApiDefined(apiDefined: dataTypesUtils.ApiDefinedIf) {
    // 获取当前接口的请求状态是否结束
    return dataUtils.getIsLoadingForApiState(apiDefined.state)
  }
}
