







































































import { Component, mixins, Ref, namespace } from 'nuxt-property-decorator'
import { throttle } from 'lodash'
import CommonCalendar from '~/components/common/calendar/index.vue'
import PackageCardList from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/package-card-list/index.vue'
import PackageOptionsBooking from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/booking/index2.0.vue'
import ShoppingCart from '~/components/experience-booking/experience-activity/activity-shopping-cart/index.vue'
import CardBottomBar from '~/components/experience-booking/experience-activity/package-options/bottom-bar/mobile/card-bottom-bar/index.vue'
import OpenDateTips from '~/components/experience-booking/experience-activity/open-date-ticket/open-date-tips/index.vue'
import NoneFilterResult from '~/components/experience-booking/experience-activity/package-options/attraction-included/components/none-filter-result.vue'
import ExpTagFilter from '~/components/experience/tag-filter/index.vue'
import { nodeScrollTop, windowScrollTop, scroll2, checkScrollStop } from '~/share/scroll2/scroll2'
import { getCss } from '~/share/utils'
import eventBus from '~/pages/experience/pay/common/event-bus'
import BookingCoreMixin from '~/components/booking-core/mixins/entry/index-base'
import AutoBookingMixin from '~/components/booking-core/mixins/common/auto-booking'
import { Activity } from '~/types/traveller/activity'
import dataUtils from '~/components/booking-core/utils/data-utils'

import * as types from '~/store/traveller/activity/types'

const ActivityModule = namespace('traveller/activity')

@Component({
  components: {
    ExpTagFilter,
    OpenDateTips,
    CommonCalendar,
    PackageOptionsBooking,
    PackageCardList,
    CardBottomBar,
    ShoppingCart,
    NoneFilterResult
  }
})
export default class CardPackageOptionsNew extends mixins(
  BookingCoreMixin,
  AutoBookingMixin
) {
  @ActivityModule.State tagFilter2state!: any
  @ActivityModule.State activityDetail!: any
  @ActivityModule.Getter('currPackageOptionTab') currPackageOptionTab!: Activity.ComboTab
  @Ref() activityBookingRef!: any

  // to do
  date: string = ''

  bookingCore = {};
  reloadTagFilter = true
  isTagFilterShowMore = false
  isFiexdTag = false
  tagFixedTop = 92
  handleScroll = throttle(this.handleCheckVisible, 60)
  packagesWithSkuByDate: any = null // any[]

  get basicData() {
    return {
      is_in_flash_sale: this.isInFlashSale,
      is_line_page: this.isLinePage
    }
  }

  get isInCombo() {
    return this.currPackageOptionTab === 'combo'
  }

  setCalendarDate(str: string) {
    this.date = str
  }

  changeTagFilter(val: string) {
    const ref: any = this.$refs.tagFilterRef
    if (this.isTagFilterShowMore) {
      ref?.setShowMore && ref.setShowMore(false)
    }
    try {
      if (this.isFiexdTag) {
        const top = nodeScrollTop(document.querySelector('.package_option_card_sku')) - 44
        scroll2({ scrollY: top })
        setTimeout(() => {
          // hack 动画冲突
          this.$store.commit(`traveller/activity/${types.SET_TAG_FILTER_VALUE}`, val)
        }, 200)
      } else {
        this.$store.commit(`traveller/activity/${types.SET_TAG_FILTER_VALUE}`, val)
      }
    } catch (error) {
      //
    }
  }

  tagsHandler(list: any[]) {
    const pidArr = (list || []).map((o: any) => o.package_id)
    const pkgArr = this.packageList.filter((o: any) => pidArr.includes(o.package_id))
    const pkgTagKeyArr = pkgArr.reduce((arr: any, obj: any) => {
      const tagsArr = (obj?.product_tags?.filter_tags || []).map((o: any) => o?.tag_key)
      arr.push(...tagsArr)
      return arr
    }, [])
    const allTagKeyArr = this.packageFilterTagList || []
    const filterArr = allTagKeyArr.filter((o: any) => pkgTagKeyArr.includes(o?.tag_key))
    return filterArr
  }

  filterSkuPackagesHandler(list: any[]) {
    const {
      date,
      tagFilter2state: { value: tagFilterValue }
    } = this
    this.reloadTagFilter = false
    this.$store.commit(`traveller/activity/${types.SET_TAG_FILTER_VALUE}`, '')
    const tags = date ? this.tagsHandler(list) : this.packageFilterTagList || []
    const tagKey = tags?.find((o: any) => o.tag_key === tagFilterValue) ? tagFilterValue : ''
    this.$store.commit(`traveller/activity/${types.SET_TAG_FILTER_LIST}`, tags)
    this.packagesWithSkuByDate = list || []
    this.$nextTick(() => {
      this.reloadTagFilter = true
      setTimeout(() => {
        // hack 动画冲突
        this.$store.commit(`traveller/activity/${types.SET_TAG_FILTER_VALUE}`, tagKey)
      }, 200)
    })
  }

  showMoreHander(isb: boolean) {
    this.isTagFilterShowMore = isb
  }

  get calcFilterPackagesWithSku() {
    const {
      tagFilter2state: { value: tagFilterValue },
      packageList,
      packagesWithSku,
      packagesWithSkuByDate
    } = this
    let skuArr = packagesWithSkuByDate || packagesWithSku || []
    if (tagFilterValue) {
      skuArr = skuArr.filter((o: any) => {
        const obj = packageList.find((pkg: any) => o.package_id === pkg.package_id)
        const filterTags = obj?.product_tags?.filter_tags
        return filterTags && filterTags.find((tag: any) => tag?.tag_key === tagFilterValue)
      })
    }
    return skuArr
  }

  get newBusinessData() {
    return {
      packageList: this.packageList,
      activity_closer_price: this.activityCloserPrice,
      dateDescription: this.dateDescription
    }
  }

  get showOpendateByPackages() {
    return this.packageList?.length > 1
  }

  get filteredPackageIdList() {
    const { packageList } = this

    return packageList.map((pkg) => pkg.package_id)
  }

  toBook(skuId: number | null, packageId: number) {
    const bookingCore = dataUtils.getBookingCoreData2vm(this)
    bookingCore.packageId = packageId
    this.bookingCore = bookingCore
    this.$nextTick(() => {
      this.activityBookingRef.open({
        date: this.date,
        packageId,
        skuId
      })
    })
  }

  get dateVisible() {
    const isOpenTicket = this.activityTicketType === 3
    return !isOpenTicket
  }

  created() {
    this.$store.commit(`traveller/activity/${types.SET_TAG_FILTER_LIST}`, this.packageFilterTagList || [])
  }

  async mounted() {
    await this.getActivitySchedulesApiData(this.calcActSchedulesParams)
    await this.scrollAndOpenSku()
    document.addEventListener('scroll', this.handleScroll)
    this.initAutoBookingMinxin({
      activityId: this.activityId,
      shopId: parseInt(this.$route.query.shop_id as string),
      shoppingCartHandler: this.showShoppingCart
    })
  }

  beforeDestroy() {
    document.removeEventListener('scroll', this.handleScroll)
  }

  handleCheckVisible() {
    // if (this.calcFilterPackagesWithSku?.length < 3) { return false }
    eventBus.$emit('getHeaderHeight2bus', (navTop: any) => {
      try {
        const skuNode: any = document.querySelector('.package_option_card_sku')
        if (!skuNode) {
          return
        }
        const skuHeight = parseInt(getCss(skuNode, 'height'))
        const node: any = document.querySelector('.js-exp-tag-filter')
        if (!node) {
          return
        }
        const windowTop = windowScrollTop()
        const navHeight = parseInt(getCss(document.querySelector('.j-activity-nav-container'), 'height')) || 0
        const oldWinTop = parseInt(node.getAttribute('data-win-top')) || 0
        const nodeTop = oldWinTop || nodeScrollTop(node)
        const tagFixedTop = parseInt(navTop) + navHeight
        const isFiexdTag =
          windowTop + tagFixedTop > nodeTop && windowTop + tagFixedTop + 204 < nodeTop + skuHeight
        node.style.top = `${tagFixedTop}px`
        if (isFiexdTag) {
          node.setAttribute('data-win-top', nodeTop)
          node.parentNode && (node.parentNode.style.height = getCss(node, 'height'))
          this.isFiexdTag = true
        } else {
          node.style.top = 0
          node.setAttribute('data-win-top', '')
          node.parentNode && (node.parentNode.style.height = 'auto')
          this.isFiexdTag = false
        }
      } catch (error) {
        //
      }
    })
  }

  async scrollAndOpenSku() {
    const { package_id } = this.$route.query
    const packageId = Number(package_id || 0)
    if (packageId > 0) {
      if ((this.packagesWithSku || []).some((pkg: any) => pkg.package_id === packageId)) {
        const elem = this.$el.querySelector(`.js-package-card[data-id="${packageId}"]`)
        if (elem) {
          elem.scrollIntoView({ behavior: 'smooth' })
          await checkScrollStop(elem)
          this.toBook(null, packageId)
        }
      } else {
        this.$logquery.service({
          timestamp: Date.now(),
          level: 'I',
          isMasked: true,
          message: JSON.stringify({
            tag_name: 'package-option-sku-not-found',
            url: this?.$route?.fullPath,
            user_agent: window?.navigator?.userAgent,
            package_id: packageId
          })
        })
      }
    }
  }
}
