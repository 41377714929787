
















































import { Vue, Component, State, Prop } from 'nuxt-property-decorator'
import FastDate from '~/components/common/calendar/fast-date.vue'
import CalendarContent from '~/components/common/calendar/content/index.vue'
import { StatusIf, ActScheduleIf, PkgScheduleIf } from '~/components/common/calendar/types'
import StatementModal from '~/components/experience-booking/experience-activity/package-options/activity-calendar/mobile/statement-modal.vue'
import CommonSkeletonSlots from '~/components/experience-booking/activity/package-detail/status/common-skeleton-slots.vue'
import PackageDetailFailure
  from '~/components/traveller/activity/activity-package-options/mobile/package-detail/status/package-detail-failure.vue'
import { getBottomSheetConfig } from '~/components/experience/load-hander/index.vue'

@Component({
  components: {
    CommonSkeletonSlots,
    PackageDetailFailure,
    StatementModal,
    CalendarContent,
    FastDate
  }
})
export default class ExpCalendar extends Vue {
  @State klook!: Data.Klook
  @Prop({ default: '' }) value!: string
  @Prop({ default: false }) isPackageSchedule!: boolean
  @Prop() schedulesState!: StatusIf
  @Prop() actSchedules!: ActScheduleIf[]
  @Prop() pkgSchedules!: PkgScheduleIf[]
  @Prop() packageId!: number
  @Prop() warn!: string
  @Prop({ default: () => [] }) legends!: any[]
  @Prop({ default: '' }) packageName!: string
  @Prop({ default: 0 }) calendarDays!: number
  @Prop({ default: () => ({}) }) fastDateProps!: { styleType: '' | 'size-normal' }

  flotingLayout: any = {
    visible: false
  }

  dateChangeHandler(val: any) {
    this.flotingLayout.visible = false
    this.$emit('input', val)
  }

  created() {
    this.flotingLayout = getBottomSheetConfig('common-config-20240925', { title: this.$t('car_rental_home_select_date') })
  }

  clickAllDateHandler() {
    this.flotingLayout.visible = true
  }

  statementVisible: boolean = false
  legendsInfo: any = {}

  showStatementModal(data: any) {
    this.legendsInfo = data
    this.statementVisible = true
  }

  delayHandler(val: boolean) {
    if (val) {
      this.$nextTick(() => {
        const ref: any = this.$refs.calendarContentRef
        typeof ref?.initScrollHandler === 'function' && ref.initScrollHandler()
      })
    }
  }
}
