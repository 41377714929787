







































































import dayjs from 'dayjs'
import { Component, Vue, Prop, Ref, State, Watch } from 'nuxt-property-decorator'
import { IconNotification } from '@klook/klook-icons'
import { getBottomSheetConfig } from '~/components/experience/load-hander/index.vue'
import CalenderTips from '~/components/experience-booking/experience-activity/package-options/activity-calendar/calendar-tips.vue'
import CalendarLengends from '~/components/experience-booking/experience-activity/package-options/activity-calendar/calendar-legend.vue'

export interface CalendarPopupSchedule {
  selling_price: number,
  soldout: boolean,
  date: string,
  disable?: boolean,
  pre_sale?: boolean
}

@Component({
  components: {
    CalenderTips,
    CalendarLengends,
    IconNotification
  }
})
export default class CalendarPopup extends Vue {
  @State klook!: Data.Klook
  @Prop() isNewOpenTicket!: boolean
  @Prop() packageId!: number
  @Prop() title!: string
  @Prop() visible!: boolean
  @Prop() date!: string
  @Prop() schedules!: CalendarPopupSchedule[]
  @Prop({ default: true }) priceVisible!: boolean
  @Ref() datePickerRef!: any
  @Prop({ default: () => [] }) legends!: any[]
  @Prop({ default: '' }) inhouseModule!: string

  bsObjSavePrice = getBottomSheetConfig('fixed-height-config', { class: '' })

  @Watch('visible', { immediate: true })
  visibleChange(val: boolean) {
    if (val) {
      this.$nextTick(() => {
        const selectedElement = this?.$el?.querySelector('.klk-date-picker-date.klk-date-picker-date-selected')
        this.scrollIntoView(selectedElement)

        if (!selectedElement) {
          const availableElement = this?.$el?.querySelector('.klk-date-picker-date:not(.klk-date-picker-date-disabled):not(.klk-date-picker-date-empty)')
          this.scrollIntoView(availableElement)
        }
      })
    }
  }

  @Watch('date', { immediate: true })
  onPackageSelectedDateChanged(val: string = '', oldValue: string = '') {
    if (this.datePickerRef) {
      const date = val ? this.getLocalDate(val) : this.defaultViewDate
      this.setViewDate(date)
    }

    if (val !== oldValue) {
      this.handleChange(val)
    }
  }

  handleChange(d: string) {
    const date = d ? this.getFormatDate(d) : ''

    const schedule = this.schedulesMap[date]
    this.$emit('input', date, schedule)
    this.$emit('change', date, schedule)
  }

  @Watch('minDate', { immediate: true })
  minDateChanged(_: string) {
    this.$nextTick(() => {
      if (this.datePickerRef && !this.calendarDate) {
        this.setViewDate(this.defaultViewDate)
        const t = setTimeout(() => {
          this.setViewDate(this.defaultViewDate)
          clearTimeout(t)
        }, 700)
      }
    })
  }

  setViewDate(val: string | Date) {
    this.datePickerRef && this.datePickerRef.setViewDate(val)
  }

  get inhouseSpm() {
    const inhouseModule = this.inhouseModule
    if (inhouseModule) {
      return {
        module: {
          'data-spm-module': inhouseModule
        },
        item: {
          'data-spm-item': 'SelectDate?trg=manual'
        }
      }
    }
    return {
      module: {},
      item: {
        'data-spm-module': 'SelectDate',
        'data-spm-virtual-item': '__virtual?trg=manual'
      }
    }
  }

  get calendarDate() {
    return this.date ? this.getLocalDate(this.date) : null
  }

  get schedulesMap() {
    return this.schedules?.reduce((acc, v) => ({ ...acc, [v.date]: v }), {} as Record<string, CalendarPopupSchedule>)
  }

  get defaultViewDate() {
    const item: any = this.schedules?.find((obj: any) => {
      return obj?.time_slots?.some((o2: any) => {
        return o2?.stock
      })
    })
    return item?.date ? this.getLocalDate(item?.date) : this.minDate
  }

  get minDate() {
    return this.getLocalDate(this.schedules?.[0]?.date)
  }

  get maxDate() {
    const len = this.schedules?.length
    return len === 0 ? null : this.getLocalDate(this.schedules?.[len - 1]?.date)
  }

  get showPreSaleIcon() {
    return this.schedules?.some(ele => !!ele?.pre_sale)
  }

  scrollIntoView(ele: Element | undefined | null) {
    if (ele) {
      ele.scrollIntoView({
        block: 'start',
        inline: 'start',
        behavior: 'smooth'
      })
    }
  }

  getLocalDate(day?: string) {
    const localeDate = dayjs(day || undefined).toString()
    return new Date(localeDate)
  }

  close() {
    this.$emit('update:visible', false)
  }

  handleDateChange(d: string) {
    const date = d ? this.getFormatDate(d) : ''
    !this.isNewOpenTicket && this.inhouseTrack(date)
    this.$emit('change', date)

    // 为了顺利的发出埋点
    setTimeout(() => {
      this.close()
    }, 100)
  }

  getPrice(d: string) {
    return this.getSchedule(d)?.selling_price
  }

  showItemLegend(item: any) {
    return !!item?.legend_keys?.length
  }

  getSchedule(d: string) {
    const date = this.getFormatDate(d)
    return this.schedulesMap[date]
  }

  getFormatDate(d: string) {
    return dayjs(d).format('YYYY-MM-DD')
  }

  shouldDateDisabled(d: string) {
    const date = this.getFormatDate(d)
    return !this.getSchedule(d) || this.isSoldout(date)
  }

  formatDay(d: string) {
    return dayjs(d).format('D')
  }

  isSoldout(d: string) {
    const date = this.getFormatDate(d)
    const schedule: CalendarPopupSchedule = this.schedulesMap[date]
    // 这里如果选不到日期是disabled，不当做售罄处理
    if (schedule) {
      return schedule.soldout
    }
  }

  isPresale(d: string) {
    const date = this.getFormatDate(d)
    const schedule: CalendarPopupSchedule = this.schedulesMap[date]
    if (schedule) {
      return schedule.pre_sale
    }
  }

  inhouseTrack(d: string) {
    const elm = this.$el.querySelector('#inhouseItem')
    const isCurrDatePresale = this.isPresale(d)
    this.$inhouse.updateBinding(elm, {
      ext: JSON.stringify({
        Date: d,
        DateType: isCurrDatePresale ? 'Unavailable Date' : 'Available Date'
      })
    })

    setTimeout(() => {
      this.$inhouse.track('action', elm)
    }, 20)
  }
}
