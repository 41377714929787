












































































import { Component, Vue, Prop, Getter, State, namespace, Inject } from 'nuxt-property-decorator'
import { throttle, isEmpty } from 'lodash'
import { HomePage } from '~/types/traveller/home-page'
import BookingBottomBtns from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/booking/bottom-bar/booking-bottom-btns.vue'
import SubscribeButton from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/booking/bottom-bar/subsribe-bottom-btns.vue'
import BookingBarWrap from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/booking/bottom-bar/booking-bar-wrap.vue'
import ExpBookingSettlement from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/booking/bottom-bar/booking-settlement'
import { Activity } from '~/types/traveller/activity'
import { ExperienceActivity } from '~/types/experience/activity-section'
import TotalSlot, { getPreTotalPriceData, getPriceSummary } from '~/components/experience/price-slot/total-slot.vue'
import { formatCurrencySymbolPriceThousands } from '~/share/utils'
import eventBus from '~/pages/experience/pay/common/event-bus'
import ShoppingCart from '~/components/experience-booking/experience-activity/activity-shopping-cart/index.vue'
import { getStandardDateFormat } from '~/share/data/datetime'
import { DefaultFieldsConfig } from '~/share/data/experience/fieldsConfigUtils.js'
import { handleIHTrack } from '~/components/experience-booking/booking-details/base/inHouseTrack'

const ActivityModule = namespace('traveller/activity')

@Component({
  components: {
    ShoppingCart,
    TotalSlot,
    BookingBarWrap,
    ExpBookingSettlement,
    BookingBottomBtns,
    SubscribeButton
  }
})
export default class BookingBottomBar extends Vue {
  @Inject({ from: 'fieldsConfig2section', default() { return DefaultFieldsConfig } }) fieldsConfig2section!: HomePage.AllFieldsIF
  @State klook!: Data.Klook
  @Prop() readyPrePrice!: boolean
  @Prop({ default: false }) custom_config_hide_credit!: boolean
  @Prop() isUnfold!: boolean
  @Prop() modificationShoppingCart!: boolean
  @Prop() hideDiscountTips!: boolean
  @Prop() units!: Activity.unit[]
  @Prop() businessData!: any
  @Prop({ default: false }) isMultiPass!: boolean
  @Prop() currentSelectedPackage!: ExperienceActivity.IPackageCard
  @Prop() selectedTime!: any
  @Prop() packageSchedules!: any
  @Prop() packageSelectedDate!: string
  @Prop() totalUnitCount!: number
  @Getter isGuestCheckout!: boolean
  @Prop() packageId!: number
  @Prop({ default: false }) isCurrPkgSelectedDatePresale!: boolean
  @Prop({ default: true }) bookingSettlementVisible!: boolean

  @ActivityModule.State pkgsPrePriceApiData!: any

  source: HTMLElement | null = null
  shoppingCartVisible = false

  completeInfo = false
  // ModuleExtra: string = JSON.stringify({ CompletedInfo: false })

  bindIHTrack(...args: any) {
    return handleIHTrack(...args)
  }

  get addToCartText() {
    return this.modificationShoppingCart ? this.$t('shoppingcart.confirm_change') : this.$t('activity.label.add_to_cart')
  }

  get arrangementId() {
    return this.selectedTime?.arrangement_id
  }

  get isPkgOpenTicket() {
    return this.currentSelectedPackage?.package_ticket_type === 3
  }

  getDatetimeFormat() {
    const { selectedTime, isPkgOpenTicket } = this
    if (isPkgOpenTicket) {
      return this.$t('24717')
    }
    let str = ''
    const { date } = selectedTime || {}
    const arr: any[] = this.packageSchedules || []
    const type = arr?.find((o: any) => o.date === this.packageSelectedDate)?.time_slots?.length === 1 ? 1 : 5
    if (date) {
      const $t = this.$t.bind(this)
      str = getStandardDateFormat(date, $t, this.klook.language, type)
    }
    return str
  }

  getCalcPreTotalPrice() {
    const str = formatCurrencySymbolPriceThousands(this.klook.currency, '-')
    if (!this.readyPrePrice) {
      return { sale_price: str }
    }
    const { pkgsPrePriceApiData, packageId } = this
    const preData = pkgsPrePriceApiData[packageId]?.result
    const ref: any = this.$refs.bookingSettlementRef
    const oldPrice = formatCurrencySymbolPriceThousands(this.klook.currency, ref?.price)
    const d = getPreTotalPriceData(this.currentSelectedPackage, preData, {
      oldPriceShow: {
        price_summary: {
          ...getPriceSummary(this.currentSelectedPackage, this.units || [], this.klook.currency)
        },
        sale_price: oldPrice
      }
    })
    const { handleCloseModalEvent, isBtnVisibleAddtocart, isMultiPass, bookingBottomBtnsBinds } = this
    const { creditsVisible, credits } = this
    const calcCredits = d?.credits || credits
    const creditsStr = this.$t('activity.new.credits', { account: Math.floor(calcCredits) })
    let tagCredits = { text: creditsStr, tag_key: 'normal-credits', background_color: '#F0FFFA', text_color: '#0DA06A', border_color: '#F0FFFA' }
    const credits_tag = this.currentSelectedPackage?.credits_tag || {}
    if (credits_tag?.text) {
      const text = `${credits_tag.text.split(' ')[0]} +${calcCredits}`
      tagCredits = { ...credits_tag, text }
    }
    if (this.readyPrePrice && this.isUnfold) {
      eventBus.$emit('updateRightPriceData2bus', {
        creditsObj: { creditsVisible, tagCredits, credits: calcCredits },
        rightPriceDetail: getPriceSummary(this.currentSelectedPackage, this.units || [], this.klook.currency, {
          datetime_format: this.getDatetimeFormat(),
          package_id: this.currentSelectedPackage.package_id
        }),
        prePriceData: d,
        handleCloseModalEvent,
        bookingBottomBtnsBinds,
        isBtnVisibleAddtocart,
        isMultiPass
      })
    } else {
      eventBus.$emit('updateRightPriceData2bus', {})
    }
    return d
  }

  get credits() {
    if (isEmpty(this.units)) {
      return 0
    }
    const credits = this.units.reduce((acc, v) => acc + parseFloat(v.credits?.toString()) * v.count, 0)

    return parseInt(credits?.toString())
  }

  get creditsVisible() {
    return !this.fieldsConfig2section.activity_custom_config_hide_credit && !this.isGuestCheckout && !this.custom_config_hide_credit && this.credits > 0
  }

  clickSavePrice(isb: boolean) {
    const priceSummary = this.bookingSettlementBinds?.preTotalPrice?.price_summary
    this.$emit('clickSavePrice', isb, { priceSummary })
  }

  get mergeTrack() {
    const selectedSkuList = (this.units || []).filter((item: Activity.unit) => item.count > 0)
    return JSON.stringify({
      CompletedInfo: this.completeInfo,
      Sku_id: selectedSkuList.map((item: Activity.unit) => item.skuId),
      Sku_num: selectedSkuList.map((item: Activity.unit) => item.count)
    })
  }

  get activityId() {
    return this.businessData.activity_id
  }

  get templateId() {
    return this.businessData.template_id
  }

  get isSupportShoppingCart() {
    return this.businessData.is_support_shopping_cart
  }

  get isOpenTicket() {
    return this.businessData.activity_ticket_type === 3
  }

  get packages() {
    return this.businessData.packages
  }

  get isSoldOut() {
    const { packages } = this
    return isEmpty(packages) || packages.every((v: any) => !v.has_stocks)
  }

  get isBtnVisibleAddtocart() {
    return ![47631, 46820, 48639, 48789, 55826, 49807, 55724].includes(this.activityId) && this.isSupportShoppingCart && !this.isGuestCheckout && !this.isMultiPass && !this.isCurrPkgSelectedDatePresale
  }

  get isBtnVisibleBookNow() {
    return !this.isCurrPkgSelectedDatePresale
  }

  get isBtnVisibleSubscribe() {
    return this.isCurrPkgSelectedDatePresale
  }

  get isInPromotion() {
    const promotionEvent = this.currentSelectedPackage?.promotion_price?.promotion_event || {}
    return promotionEvent.status === 5
  }

  get bookingSettlementBinds() {
    return {
      isSavingPoptip: false,
      preTotalPrice: this.getCalcPreTotalPrice(),
      units: this.units,
      isInPromotion: this.isInPromotion,
      promotionDetail: this.currentSelectedPackage?.promotion_price?.promotion_event
    }
  }

  toastHandler({ content }: any) {
    this.$toast(content)
  }

  get bookingBottomBtnsBinds() {
    return {
      addToCartText: this.addToCartText,
      msgFn: this.toastHandler,
      selectedTime: this.selectedTime,
      currentSelectedPackage: this.currentSelectedPackage,
      templateId: this.templateId,
      units: this.units,
      activityId: this.activityId,
      isSoldOut: this.isSoldOut,
      isOpenTicket: this.isOpenTicket,
      selectedPackagePriceSchedule: this.packageSchedules,
      packageSelectedDate: this.packageSelectedDate,
      totalUnitCount: this.totalUnitCount
    }
  }

  updateModuleExtra(val: string) {
    // this.ModuleExtra = val
    this.completeInfo = JSON.parse(val).CompletedInfo
  }

  handleCloseModalEvent() {
    this.showShoppingCart && this.showShoppingCart()
  }

  showShoppingCart() {
    this.source = this.$el.querySelector('.js-add-to-cart') as HTMLElement
    this.shoppingCartVisible = true
  }

  throttleGetFixedWrapInfos = throttle(this.getFixedWrapInfos, 300)
  getFixedWrapInfos() {
    const ref: any = this.$refs?.fixedWrapRef
    if (!ref) { return }
    const infos = {
      height: ref?.$el?.offsetHeight
    }
    this.$emit('updateInfos', infos)
    return infos
  }

  updated() {
    this.throttleGetFixedWrapInfos()
    const { arrangementId, totalUnitCount } = this
    if (!arrangementId || !totalUnitCount) {
      eventBus.$emit('updateRightPriceData2bus', {})
    }
  }
}
