



























import { Component, Vue, Prop, Inject } from 'nuxt-property-decorator'
import PackageAttributes from '~/components/experience-booking/experience-activity/package-options/package-attributes/mobile/index.vue'
import CommaText from '~/components/experience-booking/experience-activity/package-options/mobile/attribute-package-options/comma-text.vue'
import { ExperienceActivity } from '~/types/experience/activity-section'
import { scrollToPackageOption } from '~/share/scroll2/scroll2'

@Component({
  components: {
    PackageAttributes,
    CommaText
  }
})
export default class SwiperMultiAttributes extends Vue {
  @Prop() spec!: ExperienceActivity.Specs
  @Prop() value!: number[]
  @Prop() shouldPin!: boolean
  @Inject({ default: (_: any) => _ }) getScrollElBySectionConfig2provide!: Function

  get textData() {
    const { spec, value } = this
    const list = []

    if (value?.length && spec) {
      for (const item of (spec || [])) {
        for (const attr of (item.attr || [])) {
          if (value.includes(attr?.id)) {
            list.push(attr?.name)
          }
        }
      }
    }

    return list
  }

  handleClick() {
    this.$nextTick(() => {
      scrollToPackageOption(this.getScrollElBySectionConfig2provide())
    })
  }
}
