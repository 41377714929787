























































import { Component, Watch, mixins } from 'nuxt-property-decorator'
import ExpDatePicker from '~/components/common/calendar/content/exp-date-picker/index.vue'
import ExpDatePickerMixins from '~/components/common/calendar/content/exp-date-picker/mixins'
import CalendarLengends from '~/components/experience-booking/experience-activity/package-options/activity-calendar/calendar-legend.vue'
import CalenderTips from '~/components/experience-booking/experience-activity/package-options/activity-calendar/calendar-tips.vue'
import CalenderHeader from '~/components/common/calendar/content/header.vue'

@Component({
  components: {
    CalenderHeader,
    CalenderTips,
    CalendarLengends,
    ExpDatePicker
  }
})
export default class CalendarContent extends mixins(ExpDatePickerMixins) {
  isLoading: boolean = false

  @Watch('hasSchedule')
  hasScheduleChange(val: boolean) {
    this.isLoading = !val
  }

  @Watch('isPackageSchedule')
  isPackageScheduleChange(_val: boolean) {
    this.pretendLoading()
  }

  pretendLoading() {
    if (this.hasSchedule) {
      this.isLoading = true
      const t = setTimeout(() => {
        if (this.hasSchedule) {
          this.isLoading = false
        }
        clearTimeout(t)
      }, 650)
    }
  }
}
