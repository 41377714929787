

















import { Component, Vue, Prop, State } from 'nuxt-property-decorator'
import { IconChevronDown } from '@klook/klook-icons'

@Component({
  components: {
    IconChevronDown
  }
})
export default class Index extends Vue {
  @State klook!: Data.Klook
  @Prop() content!: string
  @Prop() className!: string
  @Prop() showPoptips!: boolean
  @Prop() poptipProps!: any

  isShowPopper = false

  defProps = {
    content: '', // 内容
    placement: 'bottom-end',
    'max-width': 320, // 传递字符串会告警
    'max-height': 9999, // 代替initial，传递字符串会告警
    dark: true
  }

  get calcPoptipProps() {
    const { defProps, poptipProps, content, trigger } = this
    return { ...defProps, content, trigger, ...(poptipProps || {}) }
  }

  get isDesktop() {
    return this.klook.platform === 'desktop'
  }

  get trigger() {
    return this.showPoptips ? (this.isDesktop ? 'hover' : 'click') : 'none'
  }

  handleShow() {
    this.isShowPopper = true
  }

  handleHide() {
    this.isShowPopper = false
  }
}
