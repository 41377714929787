








import { Component, namespace, State, Vue, Getter, Prop } from 'nuxt-property-decorator'
import * as sessionStorage from '~/assets/scripts/sessionstorage'
import apis from '~/share/data/apis'

const authModule = namespace('auth')

@Component
export default class SubscribeButton extends Vue {
  @State klook!: Data.Klook
  @Getter isGuestCheckout!: boolean
  @authModule.State isLoggedIn!: boolean
  @Prop() spmItem!: string
  @Prop() activityId!: number
  @Prop() currentSelectedPackage!: any
  @Prop() packageSelectedDate!: string

  get content() {
    return this.$t('175322')
  }

  get preOrderKey() { return `activity_pre_subsribe_${this.activityId}` }

  get subscribeParams() {
    return {
      activity_id: this.activityId,
      package_id: this.currentSelectedPackage?.package_id,
      date: this.packageSelectedDate
    }
  }

  get shoppingCartId() {
    const { shoppingcart_id, shop_id } = this.$route.query
    return parseInt((shoppingcart_id || shop_id) as string) || -1
  }

  getSessionData(key: string) {
    const sessionData = sessionStorage.getSessionData(this.preOrderKey)

    if (!sessionData) { return null }

    try {
      const data = JSON.parse(sessionData)

      return data && data[key]
    } catch (e) {
      return null
    }
  }

  getParams() {
    const sessionParams = this.getSessionData('params')

    if (sessionParams) {
      return sessionParams
    }

    return this.subscribeParams
  }

  async handleClick() {
    if (this.isGuestCheckout) {
      await this.handleGuestCheckout()
      return
    }

    if (!this.isLoggedIn) {
      await this.handleNoLogin()
      return
    }

    this.subscribeRequest()
  }

  async handleGuestCheckout() {
    try {
      const channel = this.$store.state.klook?.utilConfig?.f_source_type || ''
      const res = await this.$axios.$post(apis.registerGuest, {
        partner_channel: channel
      })
      if (res.success) {
        this.subscribeRequest()
      }
    } catch (e) {
      this.handleError(e)
    }
  }

  async handleNoLogin() {
    this.sendTrackingData()
    await this.$loginSDK({
      purpose: 'Subsribe',
      success: () => {
        this.subscribeRequest()
      },
      fallback: () => {
        this.saveSubscribeOptions()
      }
    })
  }

  subscribeRequest() {
    this.$showLoading({ overlayColor: 'rgba(255, 255, 255, 0.7)' })
    const params = this.getParams()
    this.$axios.$post(apis.subscribePresale, params)
      .then(res => {
        this.$hideLoading()
        if (res.success) {
          this.$alert(this.$t('175324'), this.$t('176219'), {
            okLabel: this.$t('169281')
          }).then(() => {
            this.trackSubscribeSuccess()
          })
        } else {
          this.handleError(res.error)
        }
      })
      .catch(this.handleError)
  }

  saveSubscribeOptions() {
    const preOrderKey = `activity_pre_order_${this.activityId}`
    sessionStorage.setSessionData(preOrderKey, JSON.stringify({
      params: this.subscribeParams
    }))
  }

  sendTrackingData() {
    this.$sendGTMCustomEvent('Booking Options Page|Subsribe Button Clicked')
  }

  trackSubscribeSuccess() {
    this.$inhouse.track('custom', 'body', {
      spm: 'NotifyMeSuccessfulPopUp',
      ext: JSON.stringify({ SelectDate: this.packageSelectedDate })
    })
  }

  handleError(e: { code: string, message: string }) {
    this.$confirm(e.message, {
      okLabel: this.$t('global.confirm')
    })
  }
}
