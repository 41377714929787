











import { Component } from 'nuxt-property-decorator'
import KlookEcoBadge from '@klook/klook-eco-badge'
import { handleIHTrack } from '~/components/experience-booking/booking-details/base/inHouseTrack'
import { BusinessBase } from '~/components/setion/experience/base/business-base'
import '@klook/klook-eco-badge/dist/esm/index.css'

@Component({
  components: {
    KlookEcoBadge
  }
})
export default class EcoBadge extends BusinessBase {
  static displayName = 'Eco_badge'

  get platform() {
    return this.klook.platform
  }

  get text() {
    return this.businessData?.text || ''
  }

  get link() {
    return this.businessData?.link
  }

  get imageUrl() {
    return this.businessData?.image_url
  }

  get backgroundColor() {
    return this.businessData?.background_color
  }

  get cClass() {
    return ['eco_badget', `eco_badget${this.klook.platform}`]
  }

  get trackInfo() {
    const { track = {}, activity_id = '' } = this.businessData
    const { query = {} } = track
    return this.bindIHTrack({
      ...track,
      query: {
        ...query,
        oid: `activity_${activity_id}`
      }
    })
  }

  bindIHTrack(...args: any) {
    return handleIHTrack(...args)
  }
}
