


















































































import dayjs from 'dayjs'
import { Component, Vue, Prop, Ref, State, Watch, namespace, Inject } from 'nuxt-property-decorator'
import { IconNotification } from '@klook/klook-icons'
import CalendarLengends from '~/components/experience-booking/experience-activity/package-options/activity-calendar/calendar-legend.vue'
import CalenderTips from '~/components/experience-booking/experience-activity/package-options/activity-calendar/calendar-tips.vue'
import { getBottomSheetConfig } from '~/components/experience/load-hander/index.vue'
import { ExperienceActivity } from '~/types/experience/activity-section'

const experience = namespace('experience')

export interface CalendarPopupSchedule {
  selling_price: number,
  soldout: boolean,
  date: string,
  disable?: boolean,
  legend_keys: any,
  pre_sale?: boolean
}

@Component({
  components: {
    CalendarLengends,
    CalenderTips,
    IconNotification
  }
})
export default class CalendarPopup extends Vue {
  @experience.State sectionActData!: ExperienceActivity.sectionActDataIf
  @State klook!: Data.Klook
  @Prop({ default: true }) verticalScroll!: boolean
  @Prop({ default: 0 }) minPax!: number
  @Prop({ default: 0 }) minDays!: number
  @Prop({ default: 0 }) maxDays!: number
  @Prop() templateId!: number
  @Prop() packageId!: number
  @Prop() title!: string
  @Prop() visible!: boolean
  @Prop() date!: string
  @Prop() schedules!: CalendarPopupSchedule[]
  @Prop({ default: true }) priceVisible!: boolean
  @Ref() datePickerRef!: any
  @Prop() spmPage!: any
  @Prop({ default: () => [] }) legends!: any[]
  @Prop({ default: null }) disabledFn!: Function
  @Prop({ default: 0 }) calendarDays!: number
  @Prop({ default: '' }) endDate!: string
  @Prop({ default: '' }) startDate!: string
  // 增加字段用于收敛props,日历使用场景，combo日历，pass预约,pass景点预览，有特殊逻辑处理
  @Prop({ default: '' }) usedIn!: 'combo' | 'passReserve' | 'passPreview'
  @Prop({ default: false }) headerDivider!: boolean
  @Inject({ from: 'provideaActivityId', default: 0 }) provideaActivityId!: number

  get isPassReserve() {
    return this.usedIn === 'passReserve' || this.$attrs['is-pass-reserve']
  }

  get isCombo() {
    return this.usedIn === 'combo' || this.$attrs['is-combo']
  }

  get isPassPreview() {
    return this.usedIn === 'passPreview'
  }

  get objectId() {
    if (this.packageId) {
      return `package_${this.packageId}`
    }
    if (this.provideaActivityId) {
      return `activity_${this.provideaActivityId}`
    }
    return ''
  }

  bsObjSavePrice: any = {}

  get calcMinMaxTips() {
    const { minDays, maxDays } = this
    const min = minDays <= 1 ? 1 : minDays
    const max = maxDays <= 1 ? 1 : maxDays
    let str = ''
    if (!(this.calcPicker.type === 'date-range')) {
      return ''
    }
    if (min === max && min === 1) {
      return this.$t('89656')
    } else if (min === 1) {
      str = this.$t('89263', { X: max })
    } else {
      str = this.$t('89152', { min, max })
    }
    return str
  }

  get calcPicker() {
    if (this.sectionActData.isWifi) {
      const gapDays = this.minDays >= 1 ? this.minDays - 1 : 0
      return {
        'min-range-gap': 86400 * gapDays,
        type: 'date-range'
      }
    }
    return {}
  }

  created() {
    this.$set(this, 'bsObjSavePrice', getBottomSheetConfig('fixed-height-config', { 'header-divider': true, 'can-pull-close': false, class: '', ...(this.spmPage || {}) }))
  }

  copyValue: any = ''

  @Watch('visible', { immediate: true })
  visibleChange(val: boolean) {
    if (val) {
      if (!this.verticalScroll && this.sectionActData.isWifi) {
        return
      }
      this.$nextTick(() => {
        const selectedElement = this?.$el?.querySelector('.klk-date-picker-date.klk-date-picker-date-selected')
        this.scrollIntoView(selectedElement)

        if (!selectedElement) {
          const availableElement = this?.$el?.querySelector('.klk-date-picker-date:not(.klk-date-picker-date-disabled):not(.klk-date-picker-date-empty)')
          this.scrollIntoView(availableElement)
        }
      })
    }
  }

  get calendarDate() {
    return this.date ? this.getLocalDate(this.date) : null
  }

  get schedulesMap() {
    return this.schedules?.reduce((acc, v) => ({ ...acc, [v.date]: v }), {} as Record<string, CalendarPopupSchedule>)
  }

  get defaultViewDate() {
    return this.getLocalDate()
  }

  get minDate() {
    if (this.startDate) {
      return this.getLocalDate(this.startDate)
    }
    return this.getLocalDate(this.schedules?.[0]?.date)
  }

  get maxDate() {
    if (this.endDate) {
      return this.getLocalDate(this.endDate)
    }
    const len = this.schedules?.length
    return len === 0 ? null : this.getLocalDate(this.schedules?.[len - 1]?.date)
  }

  get showBestPriceInfo() {
    return !this.packageId
  }

  get showPreSaleIcon() {
    return this.schedules?.some(ele => !!ele?.pre_sale)
  }

  scrollIntoView(ele: Element | undefined | null) {
    if (ele) {
      ele.scrollIntoView({
        block: 'start',
        inline: 'start',
        behavior: 'smooth'
      })
    }
  }

  getLocalDate(day?: string) {
    if (Array.isArray(day)) {
      return day.map((d: string) => {
        const localeDate = dayjs(d || undefined).toString()
        return new Date(localeDate)
      })
    }
    const localeDate = dayjs(day || undefined).toString()
    return new Date(localeDate)
  }

  close() {
    if (this.sectionActData.isWifi) {
      this.$emit('close')
    }
    this.$emit('update:visible', false)
  }

  everyChangeHandler(d: Date[]) {
    if (Array.isArray(d)) {
      if (this.minDays === 1 && this.maxDays === 1 && d.length === 1) {
        d.push(d[0])
      }
      const arr = d.map((v: any) => this.getFormatDate(v))
      this.$emit('everyChange', arr)
    }
  }

  handleDateChange(d: string) {
    if (this.isPassPreview) {
      return
    }

    if (Array.isArray(d)) {
      const arr = d.map((v: any) => this.getFormatDate(v))
      this.$emit('change', arr)
      return
    }
    const date = d ? this.getFormatDate(d) : ''
    this.inhouseTrack(date)
    this.$emit('change', date)

    // 为了顺利的发出埋点
    setTimeout(() => {
      this.close()
    }, 100)
  }

  showItemLegend(item: any) {
    return !!item?.legend_keys?.length
  }

  getPrice(d: string) {
    return this.getSchedule(d)?.selling_price
  }

  getSchedule(d: string) {
    const date = this.getFormatDate(d)
    return this.schedulesMap[date]
  }

  getFormatDate(d: string | Date, formatStr?: string) {
    return dayjs(d).format(formatStr || 'YYYY-MM-DD')
  }

  shouldDateDisabled(d: any) {
    if (this.disabledFn) {
      return this.disabledFn(d)
    }
    const date = this.getFormatDate(d)

    if (this.isCombo) {
      return !this.getSchedule(d) || this.getSchedule(d).soldout || this.getSchedule(d).disable
    }

    // pass日历预览，没有售罄状态，售罄当作不可用
    if (this.isPassPreview) {
      const schedule: any = this.getSchedule(d)
      return !schedule || (schedule?.time_slots?.length === 1 && schedule?.time_slots[0]?.stock === 0)
    }
    return !this.getSchedule(d) || this.isSoldout(date)
  }

  formatDay(d: string) {
    return dayjs(d).format('D')
  }

  isSoldout(d: string) {
    const date = this.getFormatDate(d)
    const schedule: any = this.schedulesMap[date]
    // pass 预约做了优化，如果timeSlots长度为1，stock为0，表示售磬
    if (this.isPassReserve && schedule) {
      const timeSlots = schedule.time_slots
      const result = timeSlots && timeSlots.length === 1 && timeSlots[0].stock === 0
      return schedule.soldout || result
    }

    // 这里如果选不到日期是disabled，不当做售罄处理
    if (schedule) {
      return schedule.soldout
    }
  }

  isPresale(d: string) {
    const date = this.getFormatDate(d)
    const schedule: CalendarPopupSchedule = this.schedulesMap[date]
    if (schedule) {
      return schedule.pre_sale
    }
  }

  showDot(d: string) {
    if (this.isPassPreview) {
      const date = this.getFormatDate(d)
      const schedule: any = this.schedulesMap[date]
      if (schedule?.time_slots?.length === 1 && schedule?.time_slots[0]?.stock === 0) {
        return false
      }
      return schedule
    }
  }

  inhouseTrack(d: string) {
    const isCurrDatePresale = this.isPresale(d)
    this.$inhouse.updateBinding(this.datePickerRef?.$el, {
      ext: JSON.stringify({
        Date: d,
        DateType: isCurrDatePresale ? 'Unavailable Date' : 'Available Date'
      })
    })

    setTimeout(() => {
      this.$inhouse.track('action', this.datePickerRef?.$el)
    }, 20)
  }
}
