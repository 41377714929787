

































import { Component, Prop } from 'nuxt-property-decorator'
import PackageDetailBase from '../package-detail-base'
import PackageDetailSuccess from './package-detail-success.vue'
import PackageDetailFailure from '~/components/traveller/activity/activity-package-options/mobile/package-detail/status/package-detail-failure.vue'
import PackageDetailEmpty
  from '~/components/traveller/activity/activity-package-options/mobile/package-detail/status/package-detail-empty.vue'
import { windowScrollTop } from '~/share/scroll2/scroll2'
import BaseHorizontalSwiper from '~/components/common/base-horizontal-swiper.vue'
import { PkgDetailsSkeleton } from '~/components/common/skeleton-data/index.js'
import { MwebAttrPkgDetail } from '~/components/common/package-detail-v2'
// import { nodeScrollTop } from '~/share/scroll2/scroll2'

@Component({
  components: {
    BaseHorizontalSwiper,
    PackageDetailEmpty,
    PackageDetailFailure,
    PackageDetailSuccess,
    MwebAttrPkgDetail
  }
})
export default class PackageDetail extends PackageDetailBase {
  @Prop({ default: false }) isEnableSwiperMode!: boolean
  @Prop({ default: false }) isEnableFirstFadeIn!: boolean
  @Prop({ default: '' }) direction!: string
  @Prop({
    type: Object,
    required: false
  }) pkgDetail: any

  PkgDetailsSkeleton = PkgDetailsSkeleton

  get translateButtonVisible() {
    return !!this.packageDetail?.enable_translation_button
  }

  get isTranslated() {
    return !this.packageDetail?.translation_status
  }

  get isAiTranslation() {
    return this.translateButtonVisible && this.isTranslated
  }

  stopFadedIn = false

  handleFadeInEnd() {
    this.stopFadedIn = true
  }

  elementHeight: string = 'unset'
  currentHeight: number = 0
  tiemer: any = null

  handleMounted(height: number) {
    if (height > this.currentHeight) {
      this.elementHeight = `${height}px`
    }
    this.currentHeight = height

    clearTimeout(this.tiemer)
    this.tiemer = setTimeout(() => {
      this.elementHeight = 'unset'
    }, 500)
  }

  handleChange() {
    this.$nextTick(() => {
      this.elementHeight = 'unset'
    })
  }

  created() {
    const { packageId, pkgDetail } = this
    if (packageId && pkgDetail) {
      // 当前套餐详情：爬虫支持服务端渲染
      this.packageDetail = pkgDetail
      this.state = 'success'
    }
    if (!this.$isServer) {
      this.winTop = windowScrollTop()
    }
  }
}
