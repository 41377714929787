

























































import dayjs from 'dayjs'
import { Component, Vue, Prop, Ref, State, Watch } from 'nuxt-property-decorator'
import CalendarLengends from '~/components/experience-booking/experience-activity/package-options/activity-calendar/calendar-legend.vue'
import CalenderTips from '~/components/experience-booking/experience-activity/package-options/activity-calendar/calendar-tips.vue'

export interface CalendarPopupSchedule {
  selling_price: number
  soldout: boolean
  date: string
  disable?: boolean
  legend_keys: any
  pre_sale?: boolean
}

@Component({
  components: {
    CalendarLengends,
    CalenderTips
  }
})
export default class DasktopDatePick extends Vue {
  @State klook!: Data.Klook
  @Prop({ default: 0 }) minPax!: number
  @Prop({ default: 0 }) minDays!: number
  @Prop({ default: 0 }) maxDays!: number
  @Prop() index!: number
  @Prop() packageId!: number
  @Prop() title!: string
  @Prop() visible!: boolean
  @Prop({ default: false }) isCombo!: boolean
  @Prop() date!: string
  @Prop() schedules!: CalendarPopupSchedule[]
  @Prop({ default: true }) priceVisible!: boolean
  @Ref() datePickerRef!: any
  @Prop() spmPage!: any
  @Prop({ default: () => [] }) legends!: any[]
  @Prop({ default: null }) disabledFn!: Function

  bsObjSavePrice: any = {}

  get calcPicker() {
    return {}
  }

  @Watch('visible', { immediate: true })
  visibleChange(val: boolean) {
    if (val) {
      setTimeout(() => {
        this.scrollIntoView()
      }, 50)
    }
  }

  get calendarDate() {
    return this.date ? this.getLocalDate(this.date) : null
  }

  get schedulesMap() {
    return this.schedules?.reduce(
      (acc, v) => ({ ...acc, [v.date]: v }),
      {} as Record<string, CalendarPopupSchedule>
    )
  }

  get defaultViewDate() {
    return this.getLocalDate()
  }

  get minDate() {
    return this.getLocalDate(this.schedules?.[0]?.date)
  }

  get maxDate() {
    const len = this.schedules?.length
    return len === 0 ? null : this.getLocalDate(this.schedules?.[len - 1]?.date)
  }

  showDot(d: string) {
    if (!this.isCombo) {
      const date = this.getFormatDate(d)
      const schedule: any = this.schedulesMap[date]
      if (schedule?.time_slots?.length === 1 && schedule?.time_slots[0]?.stock === 0) {
        return false
      }
      return schedule
    }
  }

  scrollIntoView() {
    const id = 'combon-calendar'
    const ele = document.getElementById(id)
    if (ele) {
      ele.scrollIntoView({
        block: 'center',
        inline: this.isCombo ? 'start' : 'end',
        behavior: 'smooth'
      })
    }
  }

  getLocalDate(day?: string) {
    if (Array.isArray(day)) {
      return day.map((d: string) => {
        const localeDate = dayjs(d || undefined).toString()
        return new Date(localeDate)
      })
    }
    const localeDate = dayjs(day || undefined).toString()
    return new Date(localeDate)
  }

  close() {
    this.$emit('update:visible', false)
    this.$emit('close')
  }

  passClose() {
    if (!this.isCombo) {
      this.close()
    }
  }

  everyChangeHandler(d: Date[]) {
    if (Array.isArray(d)) {
      if (this.minDays === 1 && this.maxDays === 1 && d.length === 1) {
        d.push(d[0])
      }
      const arr = d.map((v: any) => this.getFormatDate(v))
      this.$emit('everyChange', arr)
    }
  }

  handleDateChange(d: string) {
    if (!this.isCombo) {
      return
    }
    if (Array.isArray(d)) {
      const arr = d.map((v: any) => this.getFormatDate(v))
      this.$emit('change', arr)
      return
    }
    const date = d ? this.getFormatDate(d) : ''
    this.inhouseTrack(date)
    this.$emit('change', date)

    // 为了顺利的发出埋点
    setTimeout(() => {
      this.close()
    }, 100)
  }

  showItemLegend(item: any) {
    return !!item?.legend_keys?.length
  }

  getPrice(d: string) {
    return this.getSchedule(d)?.selling_price
  }

  getSchedule(d: string) {
    const date = this.getFormatDate(d)
    return this.schedulesMap[date]
  }

  getFormatDate(d: string | Date, formatStr?: string) {
    return dayjs(d).format(formatStr || 'YYYY-MM-DD')
  }

  shouldDateDisabled(d: any) {
    const date = this.getFormatDate(d)
    return !this.getSchedule(d) || this.getSchedule(d).disable || this.isSoldout(date)
  }

  formatDay(d: string) {
    return dayjs(d).format('D')
  }

  isSoldout(d: string) {
    const date = this.getFormatDate(d)
    const schedule: CalendarPopupSchedule = this.schedulesMap[date]
    // 这里如果选不到日期是disabled，不当做售罄处理
    if (schedule) {
      return schedule.soldout
    }
  }

  inhouseTrack(d: string) {
    this.$inhouse.updateBinding(this.datePickerRef?.$el, {
      ext: JSON.stringify({
        Date: d
      })
    })

    setTimeout(() => {
      this.$inhouse.track('action', this.datePickerRef?.$el)
    }, 20)
  }
}
