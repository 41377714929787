






import { Component, Vue } from 'nuxt-property-decorator'

@Component
export default class PresaleAlert extends Vue {
  get text() {
    return this.$t('175321')
  }
}
