























import { Vue, Component, Prop, State } from 'nuxt-property-decorator'
import TextShow from '~/components/common/calendar/content/text-show.vue'

@Component({
  components: {
    TextShow
  }
})
export default class CalenderHeader extends Vue {
  @State klook!: Data.Klook
  @Prop() packageId!: number
  @Prop() packageName!: string
  @Prop() warn!: string
  @Prop({ default: false }) isPackageSchedule!: boolean

  changeHandler(val: boolean) {
    setTimeout(() => {
      // inp 优化
      this.$emit('changePackageType', val)
    })
  }

  updateHandler() {
    this.$emit('updateStyles', this.$el?.getBoundingClientRect?.())
  }

  updated() {
    this.updateHandler()
  }

  mounted() {
    this.updateHandler()
  }
}
