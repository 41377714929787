import { Component, Vue, Prop } from 'nuxt-property-decorator'
import { ExperienceActivity } from '~/types/experience/activity-section'
import {
  IPackageItem,
  IPackage_group_info,
  Package
} from '~/components/experience-booking/experience-activity/package-options/mobile/group-package-options/type'

@Component
export default class PackageOptionsGroup extends Vue {
  @Prop() packages!: IPackageItem[]
  @Prop() schedule!: ExperienceActivity.ISchedule[]
  @Prop() packagesInfo!: IPackage_group_info[]
  @Prop() date!: string

  // 当前日期下可用的套餐
  get packagesInSchedule() {
    const stocks = this.schedule.find((item) => item.date === this.date)?.stocks
    if (!stocks) {
      return this.packages || []
    }
    const list = stocks.reduce((acc: any, v: any) => {
      const { stock, package_id } = v

      if (stock === 0) {
        return acc
      }

      const pkg = (this.packages || []).find((p) => p.package_id === package_id)

      if (!pkg) {
        return acc
      }

      pkg.status = 'available'

      return [...acc, pkg]
    }, [] as ExperienceActivity.Packages)
    return list
  }

  // 拿到所有的分组信息
  get packageDescList(): Package[] {
    return (this.packagesInfo || []).reduce(
      (sum: Package[], item: IPackage_group_info) => sum.concat(item.packages || []),
      []
    )
  }

  getPackages(group: Package[]): IPackageItem[] {
    // 按照分组信息的id顺序展示套餐
    return (group || [])
      .map((item) => (this.packages || []).find((pkg) => pkg.package_id === item.package_id))
      .filter((item) => item !== undefined) as IPackageItem[]
  }
}
