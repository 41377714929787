













import { Component, namespace, Getter, Prop } from 'nuxt-property-decorator'
import apis from '~/share/data/apis'
import { Activity } from '~/types/traveller/activity'
import * as sessionStorage from '~/assets/scripts/sessionstorage'
import errorCode from '~/components/traveller/activity/book-now-error-code'
import { ExperienceActivity } from '~/types/experience/activity-section'
import { getBookButtonTextId } from '~/share/data/experience/fieldsConfigUtils.js'
import { routerPush } from '~/share/utils'
import Base from '~/components/booking-core/mixins/common/simple-check'

const experience = namespace('experience')
@Component
export default class BookingOptionsBottomBtns extends Base {
  @experience.State sectionActData!: ExperienceActivity.sectionActDataIf
  @Getter isGuestCheckout!: boolean
  @Prop({ default: null }) wifiSimParams?: any
  @Prop() spmItem!: string
  @Prop() type!: 'bookNow' | 'addToCart'
  @Prop({ default: '' }) btnContent!: string
  @Prop() selectedTime!: any
  @Prop() currentSelectedPackage!: ExperienceActivity.IPackageCard
  @Prop() templateId!: number
  @Prop() units!: Activity.unit[]
  @Prop() totalUnitCount!: number
  @Prop() packageSelectedDate!: string
  @Prop() activityId!: number
  @Prop() isSoldOut!: boolean
  @Prop() isOpenTicket!: boolean
  @Prop() generateParams!: (isbooking?: boolean) => any
  @Prop({ default: false }) isJumpOldSettlement!: boolean
  @Prop() validator!: () => boolean

  role: 'bookNow' | 'addToCart' = 'bookNow'

  get calcBookButtonText() {
    return this.$t(getBookButtonTextId(this.currentSelectedPackage?.redeem_status))
  }

  get shoppingCartId() {
    const { shoppingcart_id, shop_id } = this.$route.query
    return parseInt((shoppingcart_id || shop_id) as string) || -1
  }

  get content() {
    const contents = {
      bookNow: this.calcBookButtonText,
      addToCart: this.$t('activity.label.add_to_cart')
    }
    return this.btnContent || contents[this.role]
  }

  get preOrderKey() {
    return `activity_pre_order_${this.activityId}`
  }

  getSessionData(key: string) {
    const sessionData = sessionStorage.getSessionData(this.preOrderKey)

    if (!sessionData) {
      return null
    }

    try {
      const data = JSON.parse(sessionData)

      return data && data[key]
    } catch (e) {
      return null
    }
  }

  saveBookingOptions() {
    sessionStorage.setSessionData(
      this.preOrderKey,
      JSON.stringify({
        params: this.generateParams(true)
      })
    )
  }

  toastHandler({ content }: any) {
    this.$toast(content)
    this.$businessLog({
      type: 'FECheckFail',
      tag: this.role === 'bookNow' ? 'BookNowBtn' : 'AddToCartBtn',
      reason: content
    })
  }

  async handleNoLogin() {
    this.sendData()

    // 埋点
    this.$cookies.set('reg_form_buy_btn', 1, { path: '/' })

    await this.$loginSDK({
      purpose: this.role === 'addToCart' ? 'Cart' : 'Booking',
      success: async () => {
        await this.bookRequest()
      },
      fallback: () => {
        // 保存用户选择数据，可以在引导用户登录完成之后回到页面直接下单
        this.saveBookingOptions()
      },
      cancel: () => {
        this.cancelLoginHandler()
      }
    })
  }

  async handleClick() {
    if (!this.validator()) {
      return
    }

    // guest checkout
    if (this.isGuestCheckout) {
      const channel = this.klook.utilConfig?.f_source_type || ''
      const res = await this.$axios.$post(apis.registerGuest, {
        partner_channel: channel
      })

      if (res.success) {
        this.bookRequest()
      }

      return
    }

    const canBypassLogin = await this.queryCheckSimplified()
    if (!canBypassLogin) {
      await this.handleNoLogin()
      return
    }
    this.$emit('next-btn-complete', JSON.stringify({ CompletedInfo: true }))

    this.bookRequest()
  }

  async bookRequest() {
    const api = apis.expPostPreOrderServiceNew
    const params = this.generateParams(true)

    this.$showLoading({ overlayColor: 'rgba(255, 255, 255, 0.7)' })
    try {
      const res = await this.$axios.$post(api, params)

      this.$hideLoading()

      if (res.success) {
        if (!this.getSessionData('params')) {
          this.sendData()
        }

        // 加入购物车成功埋点
        if (this.role === 'addToCart') {
          this.$inhouse.track('custom', 'body', {
            spm: 'AddToCartSuccessLoad',
            shoppingcart_id: res.result?.shoppingcart_id
          })
        }

        setTimeout(() => {
          if (this.role === 'bookNow') {
            routerPush(this, {
              name: 'ExperiencePay',
              query: {
                settlement_type: '1',
                shoppingcart_guid: res.result?.pre_order_id,
                travel_pass_unit_no: this.$route?.query?.travel_pass_unit_no,
                travel_pass_url: this.$route?.query?.travel_pass_url
              }
            })
          } else {
            this.shoppingCartId === -1
              ? this.$emit('closeModal')
              : (window.location.href = this.$href('/shoppingcart'))
          }
        })

        return
      }

      if (res?.error?.code === '4001') {
        await this.handleNoLogin()
        return
      }

      if (res.error.code === '01001007027') {
        const option = {
          content: this.$t('16995-price_change_tips'),
          btnYes: this.$t('global.tips.okTxt')
        }
        this.$alert(option.content, {
          okLable: option.btnYes
        }).then(() => {
          this.refreshWithUnits()
        })
        this.$businessLog({
          tag: this.role === 'bookNow' ? 'BookNowBtn' : 'AddToCartBtn',
          type: 'ApiError',
          reason: '价格改变了',
          code: res?.error?.code,
          message: res?.error?.message
        })
        return
      }

      this.handleError(res.error)
      this.$businessLog({
        tag: this.role === 'bookNow' ? 'BookNowBtn' : 'AddToCartBtn',
        type: 'ApiError',
        code: res?.error?.code,
        message: res?.error?.message
      })
    } catch (e) {
      const error = e as any
      this.$hideLoading()
      this.handleError(error)
      this.$businessLog({
        tag: this.role === 'bookNow' ? 'BookNowBtn' : 'AddToCartBtn',
        type: 'JsError',
        name: error?.name,
        stack: error?.stack,
        message: error?.message
      })
    }
  }

  refreshWithUnits() {
    window.location.reload()
  }

  handleError(e: { code: string; message: string }) {
    const resCode = e.code
    if (errorCode.includes(resCode)) {
      this.$confirm(e.message, {
        okLabel: this.$t('global.confirm'),
        cancelLabel: this.$t('global.tips.cancelTxt')
      }).then(({ result: isb }) => {
        if (isb) {
          window.location.reload()
        }
      })
    } else {
      this.$confirm(e.message, {
        okLabel: this.$t('global.confirm')
      })
    }
  }

  // getParams() {
  //   const sessionParams = this.getSessionData('params')

  //   if (sessionParams) {
  //     return sessionParams
  //   }

  //   return this.generateParams()
  // }

  /**
   * 数据上报
   * 未登录时跳转到登录页面前需要上报，登录页回来之后不在上报
   * 登录状态后端校验成功需要上报
   */
  sendData() {
    this.$sendGTMCustomEvent(
      `Booking Options Page|${
        this.role === 'addToCart'
          ? 'Booking Options Page Add To Cart Button Clicked'
          : 'Booking Options Page Next Button Clicked'
      }`
    )
  }

  created() {
    if (this.type) {
      this.role = this.type
    } else {
      this.role = this.$route.query.type === 'book' ? 'bookNow' : 'addToCart'
    }
  }
}
