import { Vue, Component, namespace, Prop, State } from 'nuxt-property-decorator'
import api from '~/share/data/apis'

const authModule = namespace('auth')

@Component
export default class SimplifiedCheckout extends Vue {
  @State klook!: Data.Klook
  @authModule.State isLoggedIn!: boolean
  @Prop() activityId!: number

  role: 'bookNow' | 'addToCart' = 'bookNow'
  isSimplifiedCheckout: boolean = false
  cancelLogin: boolean = false

  /**
   *  查询是否可以启用简化结算流程，返回true表示可以跳过登录，返回false表示需要登录
   */
  async queryCheckSimplified(): Promise<boolean> {
    if (this.role === 'addToCart') {
      return this.isLoggedIn
    }
    if (this.isLoggedIn) {
      return true
    }

    this.isSimplifiedCheckout = await this.fetchSimplifiedCheckoutStatus()

    const canBypassLogin = this.cancelLogin ? false : await this.checkHasHistory()

    return this.canBypass(canBypassLogin)
  }

  /**
   *  判断是否可以跳过登录进入结算页面
   */
  private canBypass(isLoggedBefore: boolean = false): boolean {
    const baseRules = this.isSimplifiedCheckout && this.role === 'bookNow' && !this.isLoggedIn
    return isLoggedBefore ? baseRules && this.cancelLogin : baseRules
  }

  /**
   *  请求后端接口，查询是否在AB测试中
   */
  private async fetchSimplifiedCheckoutStatus(): Promise<boolean> {
    try {
      const queryParams = { activity_id: this.activityId }
      const { success, result } = await this.$axios.$get(api.can_simple_checkout, { params: queryParams })
      return success ? result : false
    } catch (error) {
      return false
    }
  }

  /**
   *  检查用户是否有历史记录
   */
  private async checkHasHistory(): Promise<boolean> {
    try {
      const { checkLogin } = (await import('@klook/klook-traveller-login')) as any
      if (typeof checkLogin === 'function') {
        const { isLoggedBefore } = await checkLogin()
        return isLoggedBefore || false
      }
    } catch (error) {
    }
    return false
  }

  /**
   *  取消登录操作的处理
   *  在桌面端，直接进行跳转，不用点击第二次点才跳转
   */
  cancelLoginHandler() {
    if (this.isSimplifiedCheckout && this.role === 'bookNow') {
      this.cancelLogin = true
    }
  }
}
