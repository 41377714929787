

















import { Component, Prop, Watch } from 'nuxt-property-decorator'
import ImageGalleryViewer from './image-gallery-viewer.vue'
import ActivityLayer from '~/components/traveller/activity/layer/activity-layer.vue'
import WebVitalsMixins from '~/components/web-vitals/mixins'

@Component({
  components: {
    ActivityLayer,
    ImageGalleryViewer
  }
})
export default class ImageViewer extends WebVitalsMixins {
  @Prop() visible!: boolean
  @Prop() transfer!: boolean
  @Prop() photoType!: string
  @Prop({ default: false }) notFixed!: boolean

  @Watch('visible')
  visibleChange(val: boolean) {
    this.setLazyVisible(val)
  }

  slideChange() {
    const ext: any = { }
    ext.photo_type = this.photoType

    this.$inhouse.track('custom', 'body', {
      spm: 'BannerGallery.GalleryPhotoScroll',
      ext
    })
  }
}
