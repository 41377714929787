


























import { Component, Vue, Prop, Ref, State, Watch } from 'nuxt-property-decorator'
import dayjs from 'dayjs'

@Component({
  components: {
  }
})
export default class ExpDataPicker extends Vue {
  @State klook!: Data.Klook
  @Ref() spmItemRef!: any
  @Ref() datePickerRef!: any
  @Prop() inhouseConfig!: { spmPage: string, spmModule: string, spmItem: string }
  @Prop() datePickerProps!: any
  @Prop() value!: string | string[]

  @Watch('value', { immediate: true })
  valueWatch(val: string | string[]) {
    this.defDatePickerProps.date = this.getDate2value(val)
  }

  defDatePickerProps: any = {
    date: undefined, // Date | Date[]，赋值为null表示不选中
    type: 'date',
    width: 'auto',
    'double-panel': false,
    'should-disable-date': () => {},
    'first-day-of-week': 1,
    'min-date': undefined,
    'max-date': undefined,
    'default-view-date': undefined,
    'vertical-scroll': false
  }

  get calcDatePickerProps() {
    const obj = { ...this.defDatePickerProps, ...{ 'vertical-scroll': this.klook.platform === 'mobile' }, ...(this.datePickerProps || {}) }
    return obj
  }

  changeHandler(val: any) {
    setTimeout(() => {
      // inp 优化
      const d = this.getValue2date(val)
      this.sendTrack(d, () => {
        this.$emit('change', d)
      })
    })
  }

  defInhoudeConfig = {
    spmPage: undefined, spmModule: 'CheckAvailability', spmItem: 'SelectDate?trg=manual'
  }

  sendTrack(d: string | string[], cb?: Function) {
    const isCurrDatePresale = false // 套餐价格日历-预售日期标记，活动级别为false
    const elem = this.spmItemRef
    this.$inhouse.updateBinding(elem, {
      ext: JSON.stringify({
        Date: d,
        DateType: isCurrDatePresale ? 'Unavailable Date' : 'Available Date'
      })
    })
    this.$nextTick(() => {
      this.$inhouse.track('action', elem)
      typeof cb === 'function' && cb()
    })
  }

  get calcInhouse() {
    const obj = { ...this.defInhoudeConfig, ...(this.inhouseConfig || {}) }
    return obj
  }

  getDate2value(val: string | string[]) {
    // 入参格式化
    const localData = Array.isArray(val) ? val.map((v: string) => this.getLocalDate(v)) : this.getLocalDate(val)
    return localData
  }

  getLocalDate(val?: string) {
    const localeDate = dayjs(val || undefined).toString()
    return new Date(localeDate)
  }

  getValue2date(d: any) {
    const val = Array.isArray(d) ? d.map((v: any) => this.getFormatDate(v)) : this.getFormatDate(d)
    return val
  }

  getFormatDate(d: any) {
    return dayjs(d).format('YYYY-MM-DD') // // 回参格式化
  }

  initScrollHandler() {
    if (process.client) {
      this.$nextTick(() => {
        const selectedElement = this?.$el?.querySelector('.klk-date-picker-date.klk-date-picker-date-selected')
        this.scrollIntoView(selectedElement)
        if (!selectedElement) {
          const availableElement = this?.$el?.querySelector('.klk-date-picker-date:not(.klk-date-picker-date-disabled):not(.klk-date-picker-date-empty)')
          this.scrollIntoView(availableElement)
        }
      })
    }
  }

  scrollIntoView(ele: Element | undefined | null) {
    if (ele) {
      ele.scrollIntoView({
        block: 'start',
        inline: 'start'
      })
    }
  }
}
