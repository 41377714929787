























import { Component, State, Vue, Prop } from 'nuxt-property-decorator'
import type { TrackData } from '~/components/common/package-detail-v2/sections/type'

@Component({
  components: {}
})
export default class HeightLimitBlock extends Vue {
  @Prop({ type: Number, default: 102 }) maxHeight!: number
  @Prop({ type: Boolean, default: true }) needHeightLimit!: boolean // 是否需要折叠
  @Prop({ type: Object, default: () => ({ name: '', content: '' }) }) track!: TrackData // track信息
  @Prop() showMoreText!: string
  @Prop() showLessText!: string
  @Prop({ type: String, default: 'white' }) backgroundVariant!: string // 按钮背景渐变色
  @State klook!: Data.Klook

  isShowMore = false
  hasShowMoreBtn = false

  MAX_HEIGHT = 9999

  get viewMoreText() {
    return this.isShowMore
      ? this.showMoreText || this.$t('15834')
      : this.showLessText || this.$t('17843')
  }

  get calcShowMoreStyles() {
    if (this.isShowMore) {
      return {
        overflow: 'hidden',
        maxHeight: `${this.maxHeight}px`
      }
    }
    return {
      maxHeight: 'none'
    }
  }

  get trackInfo() {
    const { hasShowMoreBtn, isShowMore, track } = this
    const { name: trackName, content: trackSubContent } = track || {}
    return hasShowMoreBtn && trackName
      ? `ViewMore?ext=${encodeURIComponent(
          JSON.stringify({
            Status: isShowMore ? 'Less' : 'More',
            Content: trackName,
            SubContent: trackSubContent
          })
        )}`
      : null
  }

  mounted() {
    // 如果不需要做伸缩则返回
    if (!this.needHeightLimit) {
      return
    }
    this.$nextTick(() => {
      const contentDom = this?.$refs?.content as HTMLElement
      if (contentDom && contentDom?.clientHeight > this.maxHeight) {
        this.isShowMore = true
        this.hasShowMoreBtn = true
      }
    })
  }

  showMore() {
    if (this.hasShowMoreBtn) {
      this.isShowMore = !this.isShowMore
    }
  }

  moreBtnBgClass() {
    switch (this.backgroundVariant) {
      case 'gray':
        return 'more-btn-gray'
      default:
        return 'more-btn-white'
    }
  }
}
