


















































































import { Component, State } from 'nuxt-property-decorator'
import DefalutImage from '../defalutImage/index.vue'
import { Dish } from '../type'
import { mwebGalleryUrl } from '../data'
import ImageViewer from '~/components/experience-booking/experience-activity/banner/mobile/gallery/image-viewer.vue'
import Textlist from '~/components/experience-booking/experience-activity/fnb-activity-awards/components/textlist.vue'
import { BusinessBase } from '~/components/setion/experience/base/business-base'

@Component({
  components: {
    ImageViewer,
    DefalutImage,
    Textlist
  }
})
export default class MobileFnbRecommendDishes extends BusinessBase {
  static displayName = 'MobileFnb_recommend_dishes'

  @State klook!: Data.Klook

  get list(): Dish[] {
    return this.businessData.dishes || []
  }

  get isText() {
    return this.list.every((item) => !item.image_url)
  }

  get title() {
    return this.businessData.section_title || ''
  }

  get placeholderImage() {
    return this.businessData.placeholder_image || ''
  }

  get textList() {
    return this.list.map((item) => item.title)
  }

  index = 0
  visible = false
  imagevisible = false

  get imageslist() {
    return this.list.map((item) => ({
      src: item.image_url || mwebGalleryUrl,
      imageDesc: item.title
    }))
  }

  open() {
    this.visible = true
  }

  openImageViewer(index: number) {
    this.index = index
    this.imagevisible = true
  }

  handleClose() {
    this.imagevisible = false
  }

  handleTextClose() {
    this.visible = false
  }
}
