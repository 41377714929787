



































import { Component } from 'nuxt-property-decorator'
import ActivityFaqItem from '~/components/traveller/activity/activity-faq/mobile/activity-faq-item.vue'
import ActivityMarkdown from '~/components/traveller/activity/activity-markdown.vue'
import ActivityFaq from '~/components/experience-booking/experience-activity/faq/faq'
import { getBottomSheetConfig } from '~/components/experience/load-hander/index.vue'

@Component({
  components: {
    ActivityFaqItem,
    ActivityMarkdown
  }
})
export default class MobileActivityFaq extends ActivityFaq {
  static displayName = 'MobileActivity_faq'

  bsObj = getBottomSheetConfig('common-fixed-config')

  get isWhiteLabelEnv() {
    return !!this.klook?.utilConfig?.isWhiteLabelEnv
  }

  goToHelpCenter() {
    window.location.href = this.$href(this.helpCenter.link)
  }
}
