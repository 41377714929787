import { Component, Vue, Prop, Ref, Watch } from 'nuxt-property-decorator'
import { FilterData, FilterItem } from './type'

@Component
export default class FilterBase extends Vue {
  @Prop() filterData!: FilterData
  @Prop({ default: () => ([]) }) selected!: string[]
  @Prop() getNum!: Function
  @Prop() activityId!: number
  @Prop() packagesTotal!: number
  @Prop({ type: Array, default: () => [] }) packages!: any[]
  @Ref() heroFilterPopRef!: any
  @Ref() heroFilterPopConfirmRef!: any

  popVisible = false
  popSelected: string[] = []
  cacheSelected: string[] = []

  @Watch('selected', { immediate: true })
  selectedWatch(arr: any[]) {
    this.cacheSelected = arr
  }

  get quickFilters() {
    return (this.filterData?.quick_filters || []).reduce((accu, curr) => {
      for (const item of (curr?.data?.options || [])) {
        accu.push({
          ...item,
          selectValue: `${curr.key}::${item.key}::${curr.type}`
        })
      }
      return accu
    }, [] as any[])
  }

  get detailFilters() {
    return (this.filterData?.detail_filters || []).reduce((accu, curr) => {
      if (curr?.data?.options?.length) {
        accu.push({
          ...curr,
          data: {
            ...curr.data,
            options: curr.data.options.map(item => ({
              ...item,
              selectValue: `${curr.key}::${item.key}::${curr.type}`
            }))
          }
        })
      }
      return accu
    }, [] as FilterItem[])
  }

  get selectedText() {
    const len = this.selected?.length || 0
    return len > 0 ? ` · ${this.selected.length}` : ''
  }

  get popConfirmText() {
    return this.$t('82863', { num: this.popNum })
  }

  get popNum() {
    return typeof this.getNum === 'function' ? this.getNum(this.popSelected, this.packages) : 0
  }

  get selectedKeys() {
    return this.popSelected.map(item => item.split('::')?.[1])
  }

  get filterTotal() {
    return this.detailFilters.reduce((accu, curr) => accu + (curr?.data?.options?.length || 0), 0)
  }

  get filterAmount() {
    return `${this.popSelected.length}/${this.filterTotal}`
  }

  get resultPackageAmount() {
    return `${this.popNum}/${this.packagesTotal}`
  }

  computeNum(list: string[]) {
    return typeof this.getNum === 'function' ? this.getNum(list, this.packages) : 0
  }

  getQuickFilterItemSpm(item: any, len: number, index: number) {
    const ext = { SectionType: this.selected.includes(item.selectValue) ? 'Select' : 'Unselect' }
    return `EasyFilter_LIST?oid=tag_${item.key}&idx=${index}&len=${len}&ext=${encodeURIComponent(JSON.stringify(ext))}`
  }

  baseSelect(selected: string[], val: string) {
    // 浅复制，无副作用
    let res = [...selected]

    // 反选
    if (res.includes(val)) {
      return res.filter(item => item !== val)
    }

    // 单选要先去掉同一个父级的
    // eslint-disable-next-line
    const [parentKey, _, type] = val.split('::') || []
    if (type === 'single') {
      res = res.filter(item => item.split('::')?.[0] !== parentKey)
    }

    // 选中
    res.push(val)
    return res
  }

  handleSelect(val: string) {
    this.cacheSelected = this.baseSelect(this.selected, val)
    setTimeout(() => {
      this.$emit('update:selected', this.cacheSelected)
      this.$emit('change')
    }, 0)
  }

  handlePopSelect(val: string) {
    this.popSelected = this.baseSelect(this.popSelected, val)
  }

  handleSelectedClear() {
    this.selected = []
    this.$emit('update:selected', [])
    // this.$emit('change')
  }

  handlePopClear() {
    this.popSelected = []
  }

  handlePopConfirm() {
    if (this.heroFilterPopConfirmRef?.$el) {
      this.$inhouse.track('action', this.heroFilterPopConfirmRef?.$el, { force: true })
    }

    this.$emit('update:selected', [...this.popSelected])
    this.$emit('change')
    this.popVisible = false
  }

  openPop() {
    this.popSelected = [...this.selected]
    this.popVisible = true

    if (this.heroFilterPopRef?.$el) {
      setTimeout(() => this.$inhouse.track('pageview', this.heroFilterPopRef.$el, { force: true }), 100)
    }
  }
}
