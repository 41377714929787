import { render, staticRenderFns } from "./swiper-multi-attributes.vue?vue&type=template&id=27648057&scoped=true&"
import script from "./swiper-multi-attributes.vue?vue&type=script&lang=ts&"
export * from "./swiper-multi-attributes.vue?vue&type=script&lang=ts&"
import style0 from "./swiper-multi-attributes.vue?vue&type=style&index=0&id=27648057&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27648057",
  null
  
)

export default component.exports