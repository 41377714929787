





























import { Component, Vue, State, Prop, Inject } from 'nuxt-property-decorator'
import { IconPrice, IconNo, IconInformation, IconNotification } from '@klook/klook-icons'
import { SHOW_BEST_PACAKGE_PRICE_INFO, IPackageOption } from '~/components/experience-booking/experience-activity/package-options/activity-calendar/constant/index'
@Component({
  components: {
    IconPrice,
    IconNo,
    IconInformation,
    IconNotification
  }
})

export default class Index extends Vue {
  @State klook!: Data.Klook
  @Inject({ default: '' }) businessName!: IPackageOption
  @Prop({ default: true }) priceVisible!: boolean
  @Prop({ default: false }) showBestPriceInfo!: boolean
  @Prop({ default: false }) showPreSaleIcon!: boolean

  get needShowBestPriceInfo() {
    return this.businessName && SHOW_BEST_PACAKGE_PRICE_INFO.includes(this.businessName)
  }
}
