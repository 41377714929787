



















































import dayjs from 'dayjs'
import { Vue, Component, State, namespace, Prop } from 'nuxt-property-decorator'
import { ExperienceActivity } from '~/types/experience/activity-section'
import CalendarLengends from '~/components/experience-booking/experience-activity/package-options/activity-calendar/calendar-legend.vue'
import CalenderTips from '~/components/experience-booking/experience-activity/package-options/activity-calendar/calendar-tips.vue'

const experience = namespace('experience')

export interface CalendarPopupSchedule {
  selling_price: number,
  soldout: boolean,
  date: string,
  disable?: boolean,
  legend_keys: any,
  pre_sale?: boolean
}

@Component({
  components: {
    CalendarLengends,
    CalenderTips
  }
})
export default class Index extends Vue {
  @experience.State sectionActData!: ExperienceActivity.sectionActDataIf
  @State klook!: Data.Klook
  @Prop({ default: null }) disabledFn!: Function
  @Prop({ default: 0 }) minPax!: number
  @Prop({ default: 0 }) minDays!: number
  @Prop({ default: 0 }) maxDays!: number
  @Prop() schedules!: CalendarPopupSchedule[]
  @Prop() date!: string
  @Prop({ default: true }) priceVisible!: boolean
  @Prop({ default: () => [] }) legends!: any[]

  rangeDateList: string[] = []

  clearHandler() {
    const date: any = Array.isArray(this.calendarDate) ? [] : ''
    this.changeHandler(date)
  }

  get calcMinMaxTips() {
    const { minDays, maxDays } = this
    const min = minDays <= 1 ? 1 : minDays
    const max = maxDays <= 1 ? 1 : maxDays
    let str = ''
    if (!(this.calcPicker.type === 'date-range')) {
      return ''
    }
    if (min === max && min === 1) {
      return this.$t('89656')
    } else if (min === 1) {
      str = this.$t('89263', { X: max })
    } else {
      str = this.$t('89152', { min, max })
    }
    return str
  }

  get calcPicker() {
    if (this.sectionActData.isWifi) {
      const gapDays = this.minDays >= 1 ? this.minDays - 1 : 0
      return {
        'double-panel': true,
        'min-range-gap': 86400 * gapDays,
        type: 'date-range'
      }
    }
    return {}
  }

  changeHandler(d: string) {
    if (Array.isArray(d)) {
      const arr = d.map((v: any) => this.getFormatDate(v))
      this.$emit('change', arr)
      return
    }
    const date = d ? this.getFormatDate(d) : ''
    this.$emit('change', date)
  }

  everyChangeHandler(d: Date[]) {
    if (Array.isArray(d)) {
      if (this.minDays === 1 && this.maxDays === 1 && d.length === 1) {
        d.push(d[0])
      }
      const arr = d.map((v: any) => this.getFormatDate(v))
      this.rangeDateList = arr
      this.$emit('change', arr)
    }
  }

  showItemLegend(item: any) {
    return !!item?.legend_keys?.length
  }

  getPrice(d: string) {
    return this.getSchedule(d)?.selling_price
  }

  get calendarDate() {
    return this.date ? this.getLocalDate(this.date) : null
  }

  getSchedule(d: string) {
    const date = this.getFormatDate(d)
    return this.schedulesMap[date]
  }

  getFormatDate(d: string | Date, formatStr?: string) {
    return dayjs(d).format(formatStr || 'YYYY-MM-DD')
  }

  shouldDateDisabled(d: any) {
    if (this.disabledFn) {
      return this.disabledFn(d)
    }
    const date = this.getFormatDate(d)
    return !this.getSchedule(d) || this.isSoldout(date)
  }

  formatDay(d: string) {
    return dayjs(d).format('D')
  }

  isSoldout(d: string) {
    const date = this.getFormatDate(d)
    const schedule: CalendarPopupSchedule = this.schedulesMap[date]
    // 这里如果选不到日期是disabled，不当做售罄处理
    if (schedule) {
      return schedule.soldout
    }
  }

  get schedulesMap() {
    return this.schedules?.reduce((acc, v) => ({ ...acc, [v.date]: v }), {} as Record<string, CalendarPopupSchedule>)
  }

  get defaultViewDate() {
    const item: any = this.schedules?.find((obj: any) => {
      return obj?.time_slots?.some((o2: any) => {
        return o2?.stock
      })
    })
    return item?.date ? this.getLocalDate(item?.date) : this.minDate
  }

  get minDate() {
    return this.getLocalDate(this.schedules?.[0]?.date)
  }

  get maxDate() {
    const len = this.schedules?.length
    return len === 0 ? null : this.getLocalDate(this.schedules?.[len - 1]?.date)
  }

  getLocalDate(day?: string) {
    if (Array.isArray(day)) {
      return day.map((d: string) => new Date(d))
    }
    const localeDate = dayjs(day || undefined).toString()
    return new Date(localeDate)
  }
}
