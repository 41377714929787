













import { Vue, Component, Prop, Model } from 'nuxt-property-decorator'
import { merge } from 'lodash'

@Component
export default class Index extends Vue {
  @Model('change') value!: '' | 'init' | 'fetchWait' | 'fetcshSuccess' | 'fetcshError'
  @Prop() initFn!: Function
  @Prop() fetchFn!: Function

  fetchData: any = null

  async created() {
    this.initHandler()
    await this.fetchHandler()
  }

  initHandler() {
    const { initFn } = this
    this.value = ''
    initFn && initFn()
    this.$emit('change', 'init')
  }

  async fetchHandler() {
    const { fetchFn } = this
    if (fetchFn) {
      const res = await fetchFn()
      this.fetchData = res
      if (res?.success) {
        this.$emit('change', 'fetchSuccess')
      } else {
        this.$emit('change', 'fetchError')
      }
      return res
    }
    this.$emit('change', 'fetchWait')
  }

  mockValue(value: string, timer = 2000) {
    setTimeout(() => {
      this.$emit('change', value)
    }, timer)
  }
}

export const getModalConfig = (type: any = null, mergeData: any = {}) => {
  const defData = {
    transfer: true,
    open: false,
    title: '',
    'modal-class': 'exp-common-reset-modal hack-scroll-bottom',
    size: 'large',
    scrollable: false,
    closable: true,
    overlay: true,
    'overlay-closable': true,
    'esc-press-closable': true,
    'show-default-footer': false,
    'lock-scroll': true
  }
  const TypeConf: any = {
    'common-large-config': {
      width: '900px'
    },
    'common-medium-config': {
      width: '750px'
    },
    'common-small-config': {
      size: 'normal',
      width: '420px',
      closable: false,
      'overlay-closable': false,
      'esc-press-closable': false
    }
  }
  let config: any = defData
  if (typeof type === 'string') {
    config = merge(config, TypeConf[type] || {})
  } else {
    mergeData = type
  }
  config = merge(config, mergeData || {})
  return config
}

export const getBottomSheetConfig = (type: any = null, mergeData: any = {}) => {
  const defData = {
    transfer: true,
    class: 'exp-common-reset-bottom-sheet',
    visible: false,
    title: '',
    showClose: true
  }
  const TypeConf: any = {
    'common-config-20240925': {
      'delay-time': 0,
      class: 'exp-common-reset-bottom-sheet hack-date-picker',
      height: '90%',
      'can-pull-close': false
    },
    'common-config': {
      'can-pull-close': false
    },
    'common-fixed-config': {
      class: 'exp-common-reset-bottom-sheet hack-zindex-safari',
      height: '90%',
      'can-pull-close': false
    },
    'pickup-return-config': {
      height: 'auto',
      'can-pull-close': false
    },
    'package-options-config': {
      height: '90%'
    },
    'package-details-config': {
      height: '90%'
    },
    'fixed-height-config': {
      height: '90%'
    }
  }
  let config: any = defData
  if (typeof type === 'string') {
    config = merge(config, TypeConf[type] || {})
  } else {
    mergeData = type
  }
  config = merge(config, mergeData || {})
  return config
}
